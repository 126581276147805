import { auth } from '..';
import '../styling/saved.css'
import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { SIC_CODES } from './Search/Advanced/FilterTypes/siccodes';
import EditFiltersPopUp from './popUps/EditFilterPopUp';
import Moment from 'moment';
import { formatDate, getFinancialStr, getSICTitle } from './Search/Basic/SearchBar';
import { createExcel } from '../localFunctions/exportSheet';
import ExportUpdatesPopUp from './popUps/ExportUpdatesPopUp';
import { Mixpanel } from './Mixpanel/Mixpanel';

export default function SavedFilterRow({savedFilterP,deleteFilter,isExports,updateCredits,creditsP}) {


// #region CONSTANTS & STATE VARIABLES
    // const baseURL = process.env.REACT_APP_BASEURL;
// #endregion
const navigate = useNavigate();

const [savedFilter,setSavedFilter] = useState(savedFilterP) 
const [showPopUp,setShowPopUp] = useState(false) 
const [showTools,setShowTools] = useState(false) 
const [showExportPopUp,setShowExportPopUp] = useState(false) 
const primary200 = getComputedStyle(document.body).getPropertyValue('--primary-200');
const primary500 = getComputedStyle(document.body).getPropertyValue('--primary-500');
let user = JSON.parse(localStorage.getItem("user"))
// const localURL2 = process.env.REACT_APP_LOCALURL2;
const baseURL2 = process.env.REACT_APP_BASEURL2;

// #region SHOW COMPONENTS

const showFilters = () => {

    let compArray = []

    var index = 0

    savedFilter.filters.forEach(element => {

        if(index<4)
        {

            var icon
            var text

            var filter = element

            if(filter.subType === 'location')
            {
                icon = '/assets/singleBuilding.png'
                text = filter.value.locations.join(', ')
            }
            else if(filter.type === 'range')
            {
                icon = '/assets/singleBuilding.png'
                text = getFinancialStrLocal(filter.value.min,"") + '-' + getFinancialStrLocal(filter.value.max,"")
            }
            else if(filter.type === 'slider')
            {
                icon = '/assets/singleBuilding.png'
                text = filter.value.min + '-' + filter.value.max+"%"
            }
            else if(filter.type === 'multiple')
            {

                if(filter.title === "SIC Industry name")
                {
                    icon = '/assets/singleBuilding.png'
                    text = filter.value[0].title
                }
                else{
                    icon = '/assets/singleBuilding.png'
                    text = filter.value.join(', ')
                }
            }

            

            compArray.push(<div className='sfrFilters item'>
                <img className='sfrItemIcon' src={icon} alt='filterIcon'/>
                <span className='text-sm regular gray-600 sfrItemText'>{text}</span>
            </div>)

            


        }

        index++

    });


    compArray.push(
        <div className='seeAllFilters' onClick={()=>setShowPopUp(true)}>
            <span className='text-sm medium gray-600'>See all filters</span>
            <img className='safRightArrow' src='/assets/chevron-right.png' alt='rightArrow'/>
        </div>
    )

    return compArray

}

const showExportTime = () => {

    let compArray = []

    if(isExports)
    {
        console.log("EXPORT", savedFilter)
        compArray.push(
            <div className='sfrTitleColumn time'>
                <span className='text-xs regular gray-600'>{`Exported on ${Moment(savedFilter.time).format('MMM DD, YYYY')}`}</span>
                {savedFilter.lastUpdate && <span className='text-xs regular gray-600'>{`Last updated on ${Moment(savedFilter.lastUpdate).format('MMM DD, YYYY')}`}</span>}
                <div className='sfrUpdatesRow'>
                    {savedFilter.newCompanies&&savedFilter.newCompanies.length>0 && 
                        <div className='sfrUpdateBubble'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
                                <circle cx="6" cy="6.55859" r="4.5" fill={primary500} stroke={primary200} strokeWidth="3"/>
                            </svg>
                            <span className='text-xs medium primary-700'>{`${savedFilter.newCompanies.length} New Records`}</span>
                            yert
                        </div>
                    }
                    {savedFilter.updatedCompanies&&savedFilter.updatedCompanies.length>0 && 
                        <div className='sfrUpdateBubble blue'>
                            <span className='text-xs medium blue-700'>{`${savedFilter.updatedCompanies.length} Changes`}</span>
                        </div>
                    }
                </div>
            </div>
        )
        
    }

    return compArray

}



const getFinancialStrLocal = (turnover,turnoverStatus) => {

    var str = ""

    if(turnover!==null)
    {
        str+="£"

        if(turnover>=1000000 ||turnover<=-1000000)
        {
            let millions = (turnover/1000000)
            str+= millions+"M"
        }
        else{
            str += turnover.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }

        if(turnoverStatus === "Estimated")
        {
            str= "~"+str
        }

        return str
    }
    else{
        return str
    }
}



// #endregion


// #region WEB REQUESTS



// #endregion


// #region BUTTONS CLICKED
  
// #endregion


// #region OTHER
   
// #endregion


useEffect(() => {
    
        setSavedFilter(savedFilterP)
    
  }, [savedFilterP.id]); // eslint-disable-line react-hooks/exhaustive-deps


  const goToSearch = () => {
    console.log(savedFilter);
    Mixpanel.track("Load Search", {
        "User ID": auth.currentUser.uid,
        "Date": new Date().toISOString(),
        $email: auth?.currentUser?.email,
        $name: auth?.currentUser?.displayName,
        "Plan": user?.plan,
        "User Type": "Buyer",
    });
    localStorage.setItem("savedFilter",JSON.stringify(savedFilter))
    navigate(`../`, { replace: true});

  }

  const deleteFunc = () => {

    return new Promise(async (resolve, reject) => {


        axios.post(baseURL2+'deleteSavedFiltersFromAccount',{

            uid:auth.currentUser.uid,
            id:savedFilter.id

        }).then( (response) => {
        
            const data = response.data;
            console.log("SDATA",data)
            updateLocally()
            deleteFilter(savedFilter.id)
        });
    })
}


const updateLocally = () => {

        let user = JSON.parse(localStorage.getItem("user"))
        user.savedFilters = user.savedFilters.filter(x => x.id!==savedFilter.id)
        localStorage.setItem("user",JSON.stringify(user))
}

const redownload = () => {

    Mixpanel.track("FeatureUsage", {
        "User ID": auth.currentUser.uid,
        "Date": new Date().toISOString(),
        $email: auth?.currentUser?.email,
        $name: auth?.currentUser?.displayName,
        plan: user?.plan,
        "User Type": "Buyer",
        "Feature":"Redownload Export"
      });

    axios.post(baseURL2+'reExportSameBusinesses',{

        // uid:auth.currentUser.uid,
        companies:savedFilter.companies,
        timestamp:savedFilter.time

    }).then( (response) => {
    
        convertResponseReExport(response.data.companies,savedFilter.name)
    });
}

const shareEmail = () => {
    
}

// const exportNew = () => {
//     setShowTools(false)
//     setShowExportPopUp(true)
// }



const exported = (optionSelected) => {

    if(optionSelected < 3){
        savedFilter.companies.push(...savedFilter.newCompanies||[])
        savedFilter.newCompanies = []
    }
    if(optionSelected !== 2){
        savedFilter.companies.push(...savedFilter.updatedCompanies||[])
        savedFilter.updatedCompanies = []
    }

    setSavedFilter(savedFilter)

    setShowExportPopUp(false)


}

    

    return(
        <div className="savedFilterRow" onMouseLeave={()=>setShowTools(false)}>
            <div className='sfrTitle'>
                <img className='sfrTitleIcon' src={isExports?'/assets/exportDoc.png':'/assets/greyBuilding.png'} alt='titleIcon'/>
                <div className='sfrTitleColumn'>
                    <span className='text-sm medium gray-900 alignLeft'>{savedFilter.name}</span>
                    {isExports && <span className='text-sm regular gray-600 sfrItemText'>{(savedFilter.companies?savedFilter.companies.length:0)+" Companies"}</span>}
                </div>

            </div>
            <div className='sfrFilters'>
                {showFilters()}
            </div>
            <div className='row gap8 ai-centre'>
                {showExportTime()}
                <img className='sfrDots pointer' src='/assets/threeDots.png' onClick={()=>setShowTools(true)} alt='threeDots'/>
            </div>
            





            {showPopUp && <EditFiltersPopUp savedFilter={savedFilter} close={()=>setShowPopUp(false)} isExports={isExports}/>}

            {(showTools && !isExports) && <div className='searchBubble savedFilterTool'>
                <div className='sftRow' onClick={goToSearch}>
                    <img className='sftIcon' src='/assets/magnifyBlack.png' alt='magnify'/>
                    <span className='text-sm medium gray-700'>Load search</span>
                </div>
                <div className='sftRow' onClick={deleteFunc}>
                    <img className='sftIcon' src='/assets/trash.png' alt='trash'/>
                    <span className='text-sm medium gray-700'>Delete</span>
                </div>
            </div>}


            {(showTools && isExports) && <div className='searchBubble savedFilterTool export'>
                <div className='sftRow' onClick={redownload}>
                    <img className='sftIcon' src='/assets/magnifyBlack.png' alt='magnify'/>
                    <span className='text-sm medium gray-700'>Redownload CSV</span>
                </div>
                <div className='sftRow' onClick={shareEmail}>
                    <svg className='sftIcon' xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                        <path d="M4.66667 7.89193C4.04669 7.89193 3.7367 7.89193 3.48236 7.96008C2.79218 8.14501 2.25308 8.68411 2.06815 9.37429C2 9.62862 2 9.93861 2 10.5586V11.3586C2 12.4787 2 13.0388 2.21799 13.4666C2.40973 13.8429 2.71569 14.1489 3.09202 14.3406C3.51984 14.5586 4.07989 14.5586 5.2 14.5586H10.8C11.9201 14.5586 12.4802 14.5586 12.908 14.3406C13.2843 14.1489 13.5903 13.8429 13.782 13.4666C14 13.0388 14 12.4787 14 11.3586V10.5586C14 9.93861 14 9.62862 13.9319 9.37429C13.7469 8.68411 13.2078 8.14501 12.5176 7.96008C12.2633 7.89193 11.9533 7.89193 11.3333 7.89193M10.6667 5.22526L8 2.55859M8 2.55859L5.33333 5.22526M8 2.55859V10.5586" stroke="#344054" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    <span className='text-sm medium gray-700'>Share via Email</span>
                </div>
                {/* <div className='sftRow' onClick={(exportNew)}>
                    <svg className='sftIcon' xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                        <path d="M5.3335 8.55847L8.00016 11.2251M8.00016 11.2251L10.6668 8.55847M8.00016 11.2251V5.09181C8.00016 4.16467 8.00016 3.7011 7.63315 3.18207C7.3893 2.83722 6.68722 2.41159 6.26868 2.35489C5.63876 2.26954 5.39954 2.39433 4.92112 2.6439C2.78905 3.75609 1.3335 5.9873 1.3335 8.55847C1.3335 12.2404 4.31826 15.2251 8.00016 15.2251C11.6821 15.2251 14.6668 12.2404 14.6668 8.55847C14.6668 6.09086 13.3262 3.93639 11.3335 2.78369" stroke="#C01048" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    <span className='text-sm medium primary-700'>Export new version</span>
                </div> */}
                
            </div>}

            {showExportPopUp && <ExportUpdatesPopUp close={()=>setShowExportPopUp(false)} savedExport={savedFilter} updateCredits={updateCredits} creditsP={creditsP} exportSuccessful={exported}/>}
            
        </div>
    )

}



export const convertResponseReExport = (list,name) => {
    var companyList = []
    var PSCList = []
    var contactList = []
    var financialsList = []

    var compNum = ""
    var financialYear = ""
    var addPSCs

    list.forEach(item => {
        
        if(item.CompanyNumber !== compNum)
        {
            compNum = item.CompanyNumber
            financialYear = item.FinancialYear
            addPSCs = true
            //ADD Company

            companyList.push({
                name:item.CompanyName,
                number:item.CompanyNumber,
                sic:item.SIC,
                sicName:getSICTitle(item.SIC,SIC_CODES).title,
                registeredAddress:item.RegisteredAddress,
                tradingAddress:item.TradingAddress,
                website:item.Website,
                incorporation:formatDate(item.IncorporationDate),                    
                type:item.CompanyType,
                status:item.CompanyStatus,
                amountPSCs:item.AmountPSCs,
                debtRatio:(item.DebtRatio/100).toFixed(2),
                netProfitPercentage:item.NetProfitPercentage,
                fanpRatio:item.FTARevRatio,// || item.calculatedFinancials.fatr2,
                turnover:item.Turnover?getFinancialStr(item.Turnover):null,
                turnoverStatus:item.Estimated?"Estimated":"Reported",
                netProfit:item.NetProfit?getFinancialStr(item.NetProfit):null,
                currentAssets:item.CurrentAssets?getFinancialStr(item.CurrentAssets):null,
                fixedAssets:item.FixedAssets?getFinancialStr(item.FixedAssets):null,
                totalAssets:item.TotalAssets?getFinancialStr(item.TotalAssets):null,
                currentLiabilities:item.CurrentLiabilities?getFinancialStr(item.CurrentLiabilities):null,
                longTermLiabilities:item.LongTermLiabilities?getFinancialStr(item.LongTermLiabilities):null,
                netAssets:item.NetAssets?getFinancialStr(item.NetAssets):null,
                premium:item.Premium?"Premium":"Standard"
            })


            financialsList.push({
                compNumber:item.CompanyNumber,
                compName:item.CompanyName,
                sic:item.SIC,
                sicName:getSICTitle(item.SIC,SIC_CODES).title,
                
                registeredAddress:item.RegisteredAddress,
                tradingAddress:item.TradingAddress,
                website:item.website,
                incorporation:formatDate(item.IncorporationDate),                    
                type:item.CompanyType,
                status:item.CompanyStatus,
                filingDate:item.FilingDate,
                year:item.FinancialYear,
                debtRatio:item.DebtRatio,
                netProfitPercentage:item.NetProfitPercentage,
                fanpRatio:item.FTARevRatio,// || item.calculatedFinancials.fatr2,
                turnover:item.Turnover,
                turnoverStatus:item.Estimated?"Estimated":"Reported",
                netProfit:item.NetProfit,
                currentAssets:item.CurrentAssets,
                fixedAssets:item.FixedAssets,
                totalAssets:item.TotalAssets,
                currentLiabilities:item.CurrentLiabilities,
                longTermLiabilities:item.LongTermLiabilities,
                netAssets:item.NetAssets

            })
        }
        else if(item.FinancialYear !== financialYear)
        {
            financialYear = item.FinancialYear

            addPSCs = false

            financialsList.push({
                compNumber:item.CompanyNumber,
                compName:item.CompanyName,
                sic:item.SIC,
                sicName:getSICTitle(item.SIC,SIC_CODES).title,
                
                registeredAddress:item.RegisteredAddress,
                tradingAddress:item.TradingAddress,
                website:item.website,
                incorporation:formatDate(item.IncorporationDate),                    
                type:item.CompanyType,
                status:item.CompanyStatus,
                filingDate:item.FilingDate,
                year:item.FinancialYear,
                debtRatio:item.DebtRatio,
                netProfitPercentage:item.NetProfitPercentage,
                fanpRatio:item.FTARevRatio,// || item.calculatedFinancials.fatr2,
                turnover:item.Turnover,
                turnoverStatus:item.Estimated?"Estimated":"Reported",
                netProfit:item.NetProfit,
                currentAssets:item.CurrentAssets,
                fixedAssets:item.FixedAssets,
                totalAssets:item.TotalAssets,
                currentLiabilities:item.CurrentLiabilities,
                longTermLiabilities:item.LongTermLiabilities,
                netAssets:item.NetAssets

            })
        }


        if(addPSCs)
        {
            contactList.push({
                name:item.CompanyName,
                number:item.CompanyNumber,
                sic:item.SIC,
                sicName:getSICTitle(item.SIC,SIC_CODES).title,
                title:item.Title,
                firstName:item.Forename,
                surname:item.Surname,
                email:item.Email,
                linkedIn:item.LinkedIn,
                shareholderType:item.ShareholderType,
                shareholderControl:item.ShareholderPercentage,
                age:2023-item.YearDOB
            })

            PSCList.push({
                name:item.CompanyName,
                number:item.CompanyNumber,
                sic:item.SIC,
                sicName:getSICTitle(item.SIC,SIC_CODES).title,
                title:item.Title,
                firstName:item.Forename,
                surname:item.Surname,
                addressLine1:item.Address_Line1,
                addressLine2:item.Address_Line2,
                town:item.Address_PostTown,
                county:item.Address_County,
                postCode:item.PostCode,
                country:item.Country,
                shareholderType:item.ShareholderType,
                shareholderControl:item.ShareholderPercentage,
                age:2023-item.YearDOB,
                // email:item.Email
            }) 
        }


        
    });

    createExcel(name,companyList,contactList,PSCList,financialsList)
}