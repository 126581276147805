import "../../../../styling/filters.css";
import React, { useEffect, useState, useRef } from "react";
import { SIC_CODES } from "./siccodes";
import { ISIC_CODES } from "./isiccodes";
import { Mixpanel } from "../../../Mixpanel/Mixpanel";

export default function SICFilter({
  filter,
  addedFilters,
  apply,
  clear,
  isic,
}) {
  // #region CONSTANTS & STATE VARIABLES

  const [chosenOptions, setChosenOptions] = useState([]);
  const [text, setText] = useState("");
  const [updateVar, setUpdateVar] = useState(0);
  const [offset, setOffset] = useState(0);
  const [sicFocused, setSicFocused] = useState(false);

  const [anySICSelected, setAnySICSelected] = useState(false);

  let user = JSON.parse(localStorage.getItem("user"))


  const gridRef = useRef();

  //   const sicCodes = [
  //     {id:'1',sicCode:'',title:"Agriculture,Forestry and Fishing",subValues:[
  //         {id:'1A',sicCode:'',title:"Forestry and logging",subValues:[]},
  //         {id:'1B',sicCode:'',title:"Fishing and aquaculture",subValues:[
  //             {id:'1B1',sicCode:'',title:"Fly Fishing",subValues:[]},
  //             {id:'1B2',sicCode:'',title:"Trawler Fishing",subValues:[]},
  //         ]},
  //     ]},

  //     {id:'2',sicCode:'',title:"Mining and Quarrying",subValues:[]}
  //   ]

  const sicCodes = SIC_CODES;
  const isicCodes = ISIC_CODES;

  // #endregion

  useEffect(() => {
    setChosenOptions([]);

    addedFilters.forEach((element) => {
      if (element.id === filter.id) {
        setChosenOptions(element.value);
      }
    });
  }, [addedFilters.length]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    gridRef.current?.scrollIntoView({ behavior: "smooth" });
    setOffset(document.getElementById("sicInput").clientHeight - 5);
  }, [chosenOptions.length]);

  // #region SHOW COMPONENTS
  const showOptions = () => {
    let compArray = [];

    let codesArray = isic ? isicCodes : sicCodes;

    codesArray.forEach((element) => {
      if (text === "" || matchesInput(element)) {
        compArray.push(
          <SICRow
            filter={element}
            addedFilters={addedFilters}
            apply={addOption}
            input={text}
            key={element.id}
          />
        );
      }
    });

    return compArray;
  };

  const showSelected = () => {
    let compArray = [];

    chosenOptions.forEach((element) => {
      compArray.push(
        <div className="appliedFilterValue spaced" key={element.title}>
          <span className="text-xs semibold gray-700 alignLeft oneLine">
            {pascalCase(element.title)}
          </span>
          <img
            className="appliedFilterClose"
            src="/assets/x-close.png"
            onClick={() => remove(element)}
            alt="close"
          />
        </div>
      );
    });

    return compArray;
  };

  const remove = (sic) => {
    var index = 0;
    chosenOptions.forEach((element) => {
      if (element.title === sic.title) {
        chosenOptions.splice(index, 1);
      }
      index++;
    });
    setChosenOptions(chosenOptions);
    setUpdateVar(updateVar + 1);

    if (chosenOptions.length === 0) {
      clear();
    } else {
      apply(filter, chosenOptions);
    }
  };

  const matchesInput = (filter) => {
    if (
      filter.title.toLowerCase().includes(text.toLowerCase()) ||
      filter.id.toLowerCase().includes(text.toLowerCase())
    ) {
      return true;
    } else if (filter.subValues.length > 0) {
      let hasChildMatch = false;
      filter.subValues.forEach((element) => {
        if (matchesInput(element)) {
          hasChildMatch = true;
        }
      });
      return hasChildMatch;
    } else {
      return false;
    }
  };

  // #endregion

  // #region WEB REQUESTS

  // #endregion

  // #region BUTTONS CLICKED
  const addOption = (option) => {

    setAnySICSelected(true)

    if (!checkIfAlreadySelected(option, chosenOptions)) {
      chosenOptions.push(option);
      apply(filter, chosenOptions);
    }

    if (option.subValues.length > 0) {
      var filteredArray = chosenOptions;

      let subvalues = getFilterSubValues(option);
      console.log("SUBVS", subvalues, filteredArray);
      filteredArray.forEach((element) => {
        if (subvalues.includes(element.id)) {
          console.log(element.id);
          filteredArray = filteredArray.filter((x) => x.id !== element.id);
        }
      });

      apply(filter, filteredArray);
    }
  };

  const checkIfAlreadySelected = (filter, list) => {
    let selected = false;

    list.forEach((element) => {
      if (filter.id === element.id) {
        selected = true;
      } else if (
        element.subValues !== undefined &&
        element.subValues.length > 0
      ) {
        let subSelected = checkIfAlreadySelected(filter, element.subValues);
        if (subSelected) {
          selected = true;
        }
      }
    });

    return selected;
  };

  const getFilterSubValues = (filter) => {
    let subValues = [];

    filter.subValues.forEach((element) => {
      if (element.subValues.length > 0) {
        let fsv = getFilterSubValues(element);
        subValues.push(...fsv);
      }

      subValues.push(element.id);
    });

    return subValues;
  };

  // #endregion

  // #region OTHER

  useEffect(() => {
        if(anySICSelected)
        {
            Mixpanel.track("FeatureUsage", {
                $name: user?.name, 
                $email: user?.email, 
                "Plan": user?.plan,
                "User Type": "Buyer",
                "User ID": user?.uid,
                "Date": new Date().toISOString(),
                "Feature":"Applied SIC Filter"
              });
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[anySICSelected])

  // #endregion

  return (
    <div className="sicFilter">
      <div
        className={`textInput ${
          sicFocused ? "focus" : ""
        } search text-md regular gray-900`}
        id="sicInput"
      >
        <div className="searchMultipleGrid">
          {showSelected()}
          <input
            ref={gridRef}
            className="textInput inner"
            type="text"
            placeholder={filter.placeholder}
            value={text}
            onFocus={() => setSicFocused(true)}
            onBlur={() => setSicFocused(false)}
            onChange={(e) => setText(e.target.value)}
          />
          {sicFocused ? (
            <label
              onMouseDown={(e) => e.preventDefault()}
              className={`flex items-start p-[27px] rounded-[32px] flex-col gap-[10px] z-[200] h-[342px] w-[432px] overflow-y-scroll absolute left-6  bg-white focus drop-shadow-[0_20px_20px_rgba(243, 243, 245, 1)] border border-[#F3F4F5]`}
              style={{ transform: `translateY(${offset}px)` }}
            >
              {showOptions()}
            </label>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}

function SICRow({ filter, addedFilters, apply, input }) {
  const [open, setOpen] = useState(false);
  const primary600 = getComputedStyle(document.body).getPropertyValue(
    "--primary-600"
  );

  useEffect(() => {
    if (input === "" && open) {
      setOpen(false);
    }
  }, [input]); // eslint-disable-line react-hooks/exhaustive-deps

  const showOptions = (list) => {
    let compArray = [];

    list.forEach((element) => {
      if (input === "" || matchesInput(element)) {
        compArray.push(
          <SICRow
            filter={element}
            addedFilters={addedFilters}
            apply={apply}
            input={input}
            key={element.id}
          />
        );
      }
    });

    return compArray;
  };

  const titleClicked = () => {
    if (filter.subValues.length > 0) {
      setOpen(!open);
    } else {
      apply(filter);
    }
  };

  const matchesInput = (filter) => {
    if (
      filter.title.toLowerCase().includes(input.toLowerCase()) ||
      filter.id.toLowerCase().includes(input.toLowerCase())
    ) {
      return true;
    } else if (filter.subValues.length > 0) {
      let hasChildMatch = false;
      filter.subValues.forEach((element) => {
        if (matchesInput(element)) {
          hasChildMatch = true;
          if (!open) {
            setOpen(true);
          }
        }
      });
      return hasChildMatch;
    } else {
      return false;
    }
  };

  const formatStr = (str) => {
    var val = pascalCase(str);

    if (input !== "" && val.includes(input)) {
      val =
        val.slice(0, val.indexOf(input)) +
        "<b>" +
        input +
        "</b>" +
        val.slice(val.indexOf(input) + input.length, val.length);
    }

    return val;
  };

  return (
    <div className="sicRow">
      {(input === "" || matchesInput(filter)) && (
        <div className="cfTitle">
          <div className="sicInnerRow" onClick={titleClicked}>
            {filter.subValues.length === 0 && <div className="sicIndent" />}
            {filter.subValues.length !== 0 && (
              <img
                className="sicArrow"
                src={
                  open
                    ? "/assets/chevron-down.png"
                    : "/assets/chevron-right.png"
                }
                alt="arrow"
              />
            )}
            <span
              className="text-sm medium gray-700"
              dangerouslySetInnerHTML={{ __html: formatStr(filter.title) }}
            />
          </div>

          {/* <img className='cfArrow' src={'/assets/plus.png'}  onClick={()=>apply(filter)}/> */}
          <div className="cfArrow" onClick={() => apply(filter)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M7.99992 3.33398V12.6673M3.33325 8.00065H12.6666"
                stroke={primary600}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
      )}

      {open && (
        <div className="sicOptions">{showOptions(filter.subValues)}</div>
      )}
    </div>
  );
}

export const pascalCase = (name) => {
  return name.replace(/(\w)(\w*)/g, function (g0, g1, g2) {
    return g1.toUpperCase() + g2.toLowerCase();
  });
};
