import { auth } from '../..';
import '../../styling/saved.css'
import React, { useEffect, useState } from 'react';
import axios from "axios";
import Tooltip from '../constants/Tooltip';
import { SIC_CODES } from '../Search/Advanced/FilterTypes/siccodes';
import { createExcel } from '../../localFunctions/exportSheet';
import BuyCreditsPopUp from '../Billing/BuyCreditsPopUp';
import UpdatePaymentPopUp from '../Billing/UpdatePaymentPopUp';
import { Mixpanel } from '../Mixpanel/Mixpanel';

export default function SaveExportsPopUp({filters,close,companies,exportData,exportSuccessful,selectedCompanies, updateCredits}) {

    const [user, setUser] = useState(null)
    const [firstExport, setFirstExport] = useState(false)


    const [allChecked, setAllChecked] = useState(true)
    const [keyChecked, setKeyChecked] = useState(true)
    const [contactChecked, setContactChecked] = useState(true)
    const [financialsChecked, setFinancialsChecked] = useState(true)
    const [addressChecked, setAddressChecked] = useState(true)
    const [previousChecked, setPreviousChecked] = useState(true)

    const [spendBreakdown, setSpendBreakdown] = useState({})

    
    const [standardChecked, setStandardChecked] = useState(true)
    const [premiumChecked, setPremiumChecked] = useState(true)

    const [emailChecked, setEmailChecked] = useState(false)
    
    const [tradingAddressChecked, setTradingAddressChecked] = useState(false)

    const [creditsUsed,setCreditsUsed] = useState(0)
    const [companiesExporting,setCompaniesExporting] = useState([])

    const [loading, setLoading] = useState(false)

    const [standardCompanies,setStandardCompanies] = useState([])
    const [premiumCompanies,setPremiumCompanies] = useState([])

    const [showBuyCredits, setShowBuyCredits] = useState(false)
    const [showUpdatePayment, setShowUpdatePayment] = useState(false)
    const [companyEmailCountStandard, setCompanyEmailCountStandard] = useState(0)
    const [companyEmailCountPremium, setCompanyEmailCountPremium] = useState(0)

    // const baseURL2 = process.env.REACT_APP_LOCALURL2;

    const [paymentMethod, setPaymentMethod] = useState(null)

    useEffect(() => {

        let pm = JSON.parse(localStorage.getItem("paymentMethod"))
        console.log("PMPMPM",pm)
        setPaymentMethod(pm)
    },[])

    const baseURL2 = process.env.REACT_APP_BASEURL2;

    useEffect(() => {


        
        let user = JSON.parse(localStorage.getItem("user"))
        setUser(user)

        var firstExportL = false

        if(user.exports.length===0 && user.plan === "free"){
            setFirstExport(true)
            firstExportL = true
        }

        console.log("EXPORT DATA",companies)

        console.log("USER",user.companiesExported,companies)
        

        // let remainingCredits = user.credits - user.usedCredits

        var companiesAlreadyExported = []
        var companiesExporting = []

        var standards = []
        var premiums = []

        var index = 0

        let emailCountStandard = 0;
        let emailCountPremium = 0;
        companies.forEach(element => {
            if((selectedCompanies.length===0 || selectedCompanies.includes(element.number)))
            {
                    
                // if((element.premium && premiumChecked) || (!element.premium && standardChecked))
                // {


                    if(element.premium) { 
                        premiums.push(element)
                        emailCountPremium += element.validEmailCount!==0 ? 1 : 0
                    }
                    else { 
                        if(!firstExportL || standards.length < 25)
                        {


                            standards.push(element)
                            if(!firstExportL){emailCountStandard += element.validEmailCount!==0 ? 1 : 0}
                        }
                        
                    }

                    companiesExporting.push(element)

                    if(!firstExportL || index < 25) //Only check first 25 if first export
                    {
                        if(user.exports)
                        {
                            let match = user.companiesExported.filter(x => x.CompanyNumber===element.number && (new Date(element.updated).getTime()<new Date(x.ExportTime).getTime()))
                            if(match.length>0 && (match[0].EmailData || !emailChecked) && (match[0].TradingAddressData || !tradingAddressChecked)){
                                companiesAlreadyExported.push(element.number)
                            }
                            else{
                                // index++
                                index+=calculateCreditsCharge(element,match,firstExportL)
                            }       
                        }
                        else{
                            index+=calculateCreditsCharge(element,[],firstExportL)
                            // index++
                        }
                    }

                // }

                // element.emailCount = null


            }
            
        })
        setCompanyEmailCountStandard(emailCountStandard)
        setCompanyEmailCountPremium(emailCountPremium)
        
        if (previousChecked) {
            setCompaniesExporting(companiesExporting)
        } else {
            setCompaniesExporting(companiesExporting.filter(x => !companiesAlreadyExported.includes(x.number)))
        }
        
        setCreditsUsed(index)

        setStandardCompanies(standards)
        setPremiumCompanies(premiums)

        if(standards.length===0){setStandardChecked(false)}
        if(premiums.length===0){setPremiumChecked(false)}
        
    },[standardChecked,premiumChecked,emailChecked,tradingAddressChecked,previousChecked]) // eslint-disable-line react-hooks/exhaustive-deps
   

    const calculateCreditsCharge = (company,previousExport,firstExportP) => {
        
        var credits = 0
        var premiums = 0
        var standards = 0
        var emails = 0

        if(!previousExport.length>0)
        {
            if(company.premium){
                credits+=10
                premiums+=1
            }
            else{
                credits++
                standards++
            }
        }
        
        var pastExport = {EmailData:false,TradingAddressData:false}
        if(previousExport.length>0){pastExport=previousExport[0]}

        console.log("FIRST EXPORT P",firstExportP)

        if(standardChecked && !firstExportP && !company.premium  && !pastExport.EmailData && company.validEmailCount>0){
            credits+=3
            emails++
        }
        if(premiumChecked && company.premium  && !pastExport.EmailData && company.validEmailCount>0){
            credits+=3
            emails++
        }
        // if(tradingAddressChecked && !pastExport.TradingAddressData && company.tradingAddress){credits++}

        setSpendBreakdown({
            credits:credits,
            premiums:premiums,
            standards:standards,
            emails:emails
        })

        return credits
    }




    const exportFunction = async () => {

        saveFilters()
        setLoading(true)
        let lists = await getCorrectJSON()
        setLoading(false)

        let companyJSON = keyChecked?lists.companies:null
        let contactJSON = contactChecked?lists.contacts:null
        let pscJSON = addressChecked?lists.psc:null
        let financialJSON = financialsChecked?lists.financials:null

        createExcel(name===""?"Bizcrunch Export":name,companyJSON,contactJSON,pscJSON,financialJSON)


        Mixpanel.track("CreditSpend", {
            $name: user?.name, 
            $email: user?.email, 
            "Plan": user?.plan,
            "User Type": "Buyer",
            "User ID": auth.currentUser.uid,
            "Date": new Date().toISOString(),
            "Credits Spent": spendBreakdown.credits,
            "Premium": spendBreakdown.premium,
            "Standard": spendBreakdown.standards,
            "Emails": spendBreakdown.emails,
        });


        exportSuccessful(creditsUsed)
        
    }

    const [name, setName] = useState("")
    // const [error, setError] = useState("Please enter name")


    
    const saveFilters = () => {

        return new Promise(async (resolve, reject) => {

            let id = Math.random().toString(16).slice(2)
            let time = new Date().getTime()

            var compNums = []

            companiesExporting.forEach(element => {
                compNums.push(element.number)
            });

            axios.post(baseURL2+'addSavedExportsToAccount',{
                id:id,
                uid:auth.currentUser.uid,
                filters:filters,
                name:name,
                time:time,
                companies:compNums,
                email:emailChecked,
                tradingAddress:tradingAddressChecked,

            }).then( (response) => {
            
                const data = response.data;
                console.log("SDATA",data)
                addLocally(id,compNums,time)
                close()
            });
        })
    }


    const addLocally = (id,compNums,time) => {
            let user = JSON.parse(localStorage.getItem("user"))
            user.exports.push({name:name,filters:filters,id:id,companies:compNums,time:time})

            let companiesExported = user.companiesExported
            companiesExported.forEach(company => {
                if(compNums.includes(company.CompanyNumber))
                {
                    company.ExportTime = new Date()
                    company.EmailData = company.EmailData?true:emailChecked?true:false
                    company.TradingAddressData = company.TradingAddressData?true:tradingAddressChecked?true:false
                    compNums.splice(compNums.indexOf(company.CompanyNumber), 1);
                }
            });

            compNums.forEach(compNum => {
                companiesExported.push({
                    UserID:auth.currentUser.uid,
                    CompanyNumber: compNum,
                    ExportTime:new Date()
                })
            });

            user.companiesExported = companiesExported


            localStorage.setItem("user",JSON.stringify(user))
    }

    // const checkDisabled = () => {
    //     if(name === "")
    //     {
    //         if(error!=="Please enter name"){setError("Please enter name")}
    //         return true
    //     }
    //     else{

    //         var duplicate = false
    //         user.exports.forEach(element => {
    //             if(element.name === name){duplicate = true}
    //         });

    //         if(duplicate)
    //         {
    //             if(error!=="Name already exists"){setError("Name already exists")}
    //             // setError("Name already exists")
    //             return true
    //         }
    //         else{
    //             // setError("")
    //             if(error!==""){setError("")}
    //             return false
    //         }
    //     }

    // }


    const getCorrectJSON = () => {

        return new Promise(async (resolve, reject) => {
    
    
            var listJSON = {}
    
            var list = []

            if(firstExport)
            {
                standardCompanies.forEach(element => {
                    list.push(element.number)
                    listJSON[element.number] = element
        
                });
            }
            else{
                companiesExporting.forEach(element => {
                    list.push(element.number)
                    listJSON[element.number] = element
        
                });
            }

            
    
            axios.post(baseURL2+'getExtraFinancialsForBusinesses',{
                list:list
            }).then( (response) => {
            
                const data = response.data;
                var financialsList = []
    
                data.rows.forEach(item => {

                    if(!item.Turnover)
                        {
                            var tradeDebtors = item.TradeDebtors
                            if(!item.tradeDebtors)
                            {
                                tradeDebtors = item.CurrentAssets*.75
                                item.TradeDebtors = tradeDebtors
                            }

                            let lowRange = Math.floor(tradeDebtors*6/100000)*100000
                            let highRange = Math.floor(tradeDebtors*10/100000)*100000

                            item.Turnover = lowRange+" - "+highRange
                            item.Estimated = true
                        }

                        if(!item.NetProfit)
                        {
                            item.NetProfit = item.NetAssets
                        }

                        item.NetProfitPercentage = ((item.NetProfit/item.Turnover)*100).toFixed(2)
                        if((item.Turnover+"").includes(" - "))
                        {
                            let split = item.Turnover.split(" - ")
                            let middle = (+split[0] + +split[1])/2
                            item.NetProfitPercentage = ((item.NetProfit/middle)*100).toFixed(2)
                        }

    
    
                    financialsList.push({
                        compNumber:item.CompanyNumber,
                        compName:listJSON[item.CompanyNumber].name,
                        sic:listJSON[item.CompanyNumber].sic,
                        sicName:getSICTitle(listJSON[item.CompanyNumber].sic,SIC_CODES).title,
                        
                        // registeredAddress:listJSON[item.CompanyNumber].registeredAddress,
                        // tradingAddress:listJSON[item.CompanyNumber].tradingAddress,
                        website:listJSON[item.CompanyNumber].website,
                        incorporation:formatDate(listJSON[item.CompanyNumber].incorporation),
                        type:listJSON[item.CompanyNumber].type,
                        status:listJSON[item.CompanyNumber].status,
                        filingDate:listJSON[item.CompanyNumber].filingDate,//+"/"+item.FinancialYear,
                        year:item.FinancialYear,
                        debtRatio:(item.DebtRatio/100).toFixed(2),
                        netProfitPercentage:item.NetProfitPercentage,
                        fanpRatio:item.FTARevRatio,// || item.calculatedFinancials.fatr2,
                        turnover:item.Turnover?getFinancialStr(item.Turnover):null,
                        turnoverStatus:item.Estimated?"Estimated":"Reported",
                        netProfit:item.NetProfit?getFinancialStr(item.NetProfit):item.NetAssets?getFinancialStr(item.NetAssets):null,
                        netProfitEstimate:item.NetProfitEstimate?"Estimated":"Reported",
                        currentAssets:item.CurrentAssets?getFinancialStr(item.CurrentAssets):null,
                        fixedAssets:item.FixedAssets?getFinancialStr(item.FixedAssets):null,
                        totalAssets:item.TotalAssets?getFinancialStr(item.TotalAssets):null,
                        currentLiabilities:item.CurrentLiabilities?getFinancialStr(item.CurrentLiabilities):null,
                        longTermLiabilities:item.LongTermLiabilities?getFinancialStr(item.LongTermLiabilities):null,
                        netAssets:item.NetAssets?getFinancialStr(item.NetAssets):null
    
                    })
                });


                var companiesList = []
                var contactList = []
                var pscList = []

                console.log("Export DATA",exportData)


                exportData.companyJSONList.forEach(element => {
                    element.emailCount = null
                    element.validEmailCount = null
                    if(!tradingAddressChecked){element.tradingAddress = ""}
                    if(list.includes(element.number)){companiesList.push(element)}
                    element.premium = element.premium?"Premium":"Standard"
                });
                exportData.contactJSONList.forEach(element => {
                    
                    if(element.email==="null" || (!standardChecked && !premiumChecked)){element.email = ""}
                    if(element.linkedIn==="null"){element.linkedIn = ""}

                    if(user.plan==="free"){
                        element.email = "Upgrade to access email"
                        element.emailStatus = "N/A"
                    }

                    if(list.includes(element.number)){contactList.push(element)}
                });
                exportData.pscJSONList.forEach(element => {
                    console.log("PSC HERE",element,standardChecked,premiumChecked)

                    if(element.email==="null" || (!standardChecked && !premiumChecked)){element.email = ""}

                    if(user.plan==="free"){
                        element.email = "Upgrade to access email"
                        element.emailStatus = "N/A"
                    }

                    if(list.includes(element.number)){pscList.push(element)}
                });
    

                console.log("PSC LIST",pscList)

                resolve({
                    companies:companiesList,
                    contacts:contactList,
                    psc:pscList,
                    financials:financialsList
                })
                
    
            });
        })
    }

    const formatDate = (dateP) => {

        let date = new Date(dateP)
    
        return date.getDate() + "-" + (date.getMonth()+1)+ "-" + date.getFullYear()
    }

    const getFinancialStr = (number) => {

        if((number+"").includes(" - "))
        {
            let split = (number+"").split(" - ")
            return split[0] + " - " + split[1]
        }
        else{

            return number

            // var str = ""
            // str+="£"
            // str += number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            
            // return str
        }

        
    }

    const getSICTitle = (code,sicJSON) => {
    
        var foundSIC = false
    
        for(const element of sicJSON)
        {
            if(element.sicCode === code)
            {
                foundSIC = true
                return {found:true,title:element.title}
            }
            else{
                if(element.subValues.length>0)
                {
                    let res = getSICTitle(code,element.subValues)
                    if(res.found){
                        foundSIC = true
                        return {found:true,title:res.title}
                    }
                }
            }
            
        };
    
        if(!foundSIC)
        {
            return {found:false}
        }
        
    }

    const confirmEditPayment = () => {
        setShowBuyCredits(false)
        setShowUpdatePayment(true)
     }

     const closePayment = () => {
        setShowUpdatePayment(false)
      }

      const paymentMethodAdded = (pm) => {

        setPaymentMethod(pm)
        paymentCancel(pm)
      }
    
      const paymentCancel = (pm) => {
        setShowUpdatePayment(false)
    
        if(pm ===null || pm === undefined)
        {
            setShowBuyCredits(true)
        }
        
      }



    const checkBoxClicked = (type,checked) => {

        if (!checked) {
            setAllChecked(false)
        }
        if(!checked && type==="all"){
            setAllChecked(false)
            setKeyChecked(false)
            setContactChecked(false)
            setFinancialsChecked(false)
            setAddressChecked(false)
            setPreviousChecked(false)
        }

        if(checked && type==="all"){
            setAllChecked(true)
            setKeyChecked(true)
            setContactChecked(true)
            setFinancialsChecked(true)
            setAddressChecked(true)
            setPreviousChecked(true);
        }
        
        if(type==="key"){setKeyChecked(!keyChecked)}
        if(type==="contact"){setContactChecked(!contactChecked)}
        if(type==="financials"){setFinancialsChecked(!financialsChecked)}
        if(type==="address"){setAddressChecked(!addressChecked)}
        if(type==="previous"){setPreviousChecked(!previousChecked)}

        if(type==="standard"){setStandardChecked(!standardChecked)}
        if(type==="premium"){setPremiumChecked(!premiumChecked)}
        if(type==="email"){setEmailChecked(!emailChecked)}
        if(type==="trading"){setTradingAddressChecked(!tradingAddressChecked)}

    }

    useEffect (() => {
        if (keyChecked && contactChecked && financialsChecked && addressChecked && previousChecked) {
            setAllChecked(true)
        }
    }, [keyChecked, contactChecked, financialsChecked, addressChecked, previousChecked])

    const paySuccess = () => {
        console.log("BEC Pay Success")
        setShowBuyCredits(false)
        user.credits = user.credits + (creditsUsed-(user.credits - user.usedCredits))
        updateCredits(user.credits)
    }


    return(

        <div className='tint'>
            <div className='popUp export'>
                
                {!loading && <div className="column padding24 gap32 ai-start flexGrow">
                   
                    <div className='column ai-start gap24'>
                        <img className='sfpuIcon' src='/assets/downArrowCircle.png' alt='downArrow'/>
                        <span className='display-sm semibold gray-900'>{`Export companies to Excel`}</span>
                        {/* <span className='display-sm semibold gray-900'>{`Export ${companiesExporting.length} companies`}</span> */}
                    </div>

                    <div className='sfpuMain inner'>
                        <span className='text-sm medium gray-700'>File name</span>
                        <input className={`textInput text-md gray-900`} type="text" placeholder={"Enter file name"} value={name} onChange={(e) => setName(e.target.value)} />
                    </div>

                    <div className='column ai-start gap24'>
                        <span className='text-sm medium gray-700'>Select company data to export</span>
                        <div className='exportGrid'>
                            <div className='sepuGridRow'>
                                <input id='checkbox_id'  className='input-sm checkbox' type="checkbox" checked={allChecked} onChange={(e)=>checkBoxClicked("all",e.target.checked)}/>
                                <label for="checkbox_id" className='checkboxLabel'/>
                                <span className='text-sm semibold gray-700'>All data</span>
                            </div>

                            <div className='sepuGridRow'>
                                <input id='checkbox_id2'  className='input-sm checkbox' type="checkbox" checked={keyChecked} onChange={(e)=>checkBoxClicked("key",e.target.checked)}/>
                                <label for="checkbox_id2" className='checkboxLabel'/>
                                <span className='text-sm medium gray-700'>Key data</span>
                                <Tooltip icon={'normal'} text={"Key data"}/>
                            </div>

                            <div className='sepuGridRow'>
                                <input id='checkbox_id4'  className='input-sm checkbox' type="checkbox" checked={financialsChecked} onChange={(e)=>checkBoxClicked("financials",e.target.checked)}/>
                                <label for="checkbox_id4" className='checkboxLabel'/>
                                <span className='text-sm medium gray-700'>Financials</span>
                                <Tooltip icon={'normal'} text={"Key data"}/>
                            </div>

                            <div className='sepuGridRow'>
                                <input id='checkbox_id5'  className='input-sm checkbox' type="checkbox" checked={addressChecked} onChange={(e)=>checkBoxClicked("address",e.target.checked)}/>
                                <label for="checkbox_id5" className='checkboxLabel'/>
                                <span className='text-sm medium gray-700'>Address data</span>
                                <Tooltip icon={'normal'} text={"Address data"}/>
                            </div>

                            <div className='sepuGridRow'>
                                <input id='checkbox_id3'  className='input-sm checkbox' type="checkbox" checked={contactChecked} onChange={(e)=>checkBoxClicked("contact",e.target.checked)}/>
                                <label for="checkbox_id3" className='checkboxLabel'/>
                                <span className='text-sm medium gray-700'>Contact data</span>
                                <Tooltip icon={'normal'} text={"Key data"}/>
                            </div>

                            

                            <div className='sepuGridRow'>
                                <input id='checkbox_id6'  className='input-sm checkbox' type="checkbox" checked={previousChecked} onChange={(e)=>checkBoxClicked("previous",e.target.checked)}/>
                                <label for="checkbox_id6" className='checkboxLabel'/>
                                <span className='text-sm medium gray-700'>Inc. previous exported</span>
                                <Tooltip icon={'normal'} text={"Previously exported companies"}/>
                            </div>

                        </div>
                    </div>

                </div>}

                {!loading && <div className="column ai-start exportRight">
                    <div className='width100 row jc-end'>
                        <img className='closeButtonExport pointer' src='/assets/x-close.png' onClick={close} alt='close'/>
                    </div>

                    <div className="width100 column ai-start">
                        <div className="width100 column ai-start gap20">

                            <div className='row ai-centre jc-between width100'>
                                <span className='gray-900 text-lg semibold'>Records to export</span>
                                <span className='primary-700 text-xs medium'>About Credits</span>
                            </div>

                            <div className='width100 column ai-start gap12'>
                                <div className='width100 row ai-centre jc-between text-sm gray-700 medium'>
                                    <span>{standardCompanies.length} Standard Companies</span>
                                    <span>{standardCompanies.length + ((standardChecked&&!firstExport)?(standardCompanies.filter(x=>x.validEmailCount>0)).length*3:0)}</span>
                                </div>
                                {!firstExport && <div className='row ai-centre gap8'>
                                    <input id='checkbox_id7'  className='input-sm checkbox' type="checkbox" checked={standardChecked} onChange={(e)=>checkBoxClicked("standard",e.target.checked)} disabled={standardCompanies.length===0}/>
                                    <label for="checkbox_id7" className='checkboxLabel'/>
                                    <span className='text-xs regular gray-700'>incl. verified emails ({companyEmailCountStandard})</span>
                                </div>}
                            </div>

                            {firstExport && <span className='primary-700 semibold text-md alignLeft'>Use your free BizCrunch credits and export {standardCompanies.length} standard companies!</span>}

                            {!firstExport && <div className='width100 column ai-start gap12'>
                                <div className='width100 row ai-centre jc-between text-sm gray-700 medium'>
                                    <span>{premiumCompanies.length} Premium Companies</span>
                                    <span>{premiumCompanies.length*10 + (premiumChecked?(premiumCompanies.filter(x=>x.validEmailCount>0)).length*3:0)}</span>
                                </div>
                                <div className='row ai-centre gap8'>
                                    <input id='checkbox_id8'  className='input-sm checkbox' type="checkbox" checked={premiumChecked} onChange={(e)=>checkBoxClicked("premium",e.target.checked)} disabled={premiumCompanies.length===0}/>
                                    <label for="checkbox_id8" className='checkboxLabel'/>
                                    <span className='text-xs regular gray-700'>incl. verified emails ({companyEmailCountPremium})</span>
                                </div>
                            </div>}
                            
                        </div>

                        <div className='exportLine'/>

                        <div className='width100 column ai-start gap12'>
                            <div className='width100 row ai-centre jc-between text-sm gray-700 medium'>
                                <span>Total credits to use</span>
                                <span>{creditsUsed}</span>
                            </div>
                            <div className='width100 row ai-centre jc-between text-sm gray-700 medium'>
                                <span>Your credits balance</span>
                                {user && <span>{user.credits - user.usedCredits}</span>}
                            </div>
                        </div>


                        <div className='exportLine'/>

                        {(user && (user.credits - user.usedCredits)>=creditsUsed) && <div className="width100 row ai-centre jc-between">
                            <div className="width100 row ai-centre gap8">
                                <img className='sfrItemIcon' src='/assets/coinsStacked.png' alt='coinStacked'/>
                                <span className='gray-500 text-sm medium'>Credits left</span>
                            </div>
                            <span className='gray-500 text-sm medium'>{(user.credits - user.usedCredits)-creditsUsed}</span>
                        </div>}

                        {(user && (user.credits - user.usedCredits)<creditsUsed) && <div className='exportBuyMore row ai-centre jc-between'>
                            <div className='row ai-centre gap8'>
                                <img className='sfrItemIcon' src='/assets/coinsStacked.png' alt='coinStacked'/>
                                <div className='column ai-start jc-centre'>
                                    <span className='gray-700 text-xs semibold'>{creditsUsed-(user.credits - user.usedCredits)} more credits</span>
                                    <span className='gray-700 text-xs regular'>needed to export</span>
                                </div>
                            </div>

                            <button className='primaryButton' onClick={()=>setShowBuyCredits(true)} disabled={user.plan==="Free"}>Buy more</button>
                        </div>}

                    </div>

                    <button className='primaryButton width100' disabled={(user && (user.credits - user.usedCredits)<creditsUsed)} onClick={exportFunction}>Export {!firstExport?companiesExporting.length:standardCompanies.length} Records</button>
                    
                    {showBuyCredits && <BuyCreditsPopUp close={()=>setShowBuyCredits(false)} editPayment={confirmEditPayment} paymentMethod={paymentMethod} complete={paySuccess} option={{credits:(creditsUsed-(user.credits - user.usedCredits))<5?5:creditsUsed-(user.credits - user.usedCredits), rate:.15}} updateCredits={updateCredits}/>}
                    {showUpdatePayment && <UpdatePaymentPopUp close={closePayment} setCard={(x)=>paymentMethodAdded(x)} cancel={paymentCancel}/>}

                </div>}
                
                

                {/* <div className='sfpuMain inner2'>
                        <span className='text-sm medium gray-700'>Select company data to export</span>
                        <div className='sepuGrid'>
                           
                            <div className='sepuGridRow'>
                                <input id='checkbox_id2'  className='input-md checkbox' type="checkbox" checked={standardChecked} onChange={(e)=>checkBoxClicked("standard",e.target.checked)}/>
                                <label for="checkbox_id2" className='checkboxLabel'/>
                                <span className='text-sm medium gray-700'>Standard Companies</span>
                                <Tooltip icon={'normal'} text={"Key data"}/>
                            </div>

                            <div className='sepuGridRow'>
                                <input id='checkbox_id3'  className='input-md checkbox' type="checkbox" checked={premiumChecked} onChange={(e)=>checkBoxClicked("premium",e.target.checked)}/>
                                <label for="checkbox_id3" className='checkboxLabel'/>
                                <span className='text-sm medium gray-700'>Premium Companies</span>
                                <Tooltip icon={'normal'} text={"Key data"}/>
                            </div>

                            <div className='sepuGridRow'>
                                <input id='checkbox_id4'  className='input-md checkbox' type="checkbox" checked={emailChecked} onChange={(e)=>checkBoxClicked("email",e.target.checked)}/>
                                <label for="checkbox_id4" className='checkboxLabel'/>
                                <span className='text-sm medium gray-700'>Email</span>
                                <Tooltip icon={'normal'} text={"Key data"}/>
                            </div>

                            <div className='sepuGridRow'>
                                <input id='checkbox_id5'  className='input-md checkbox' type="checkbox" checked={tradingAddressChecked} onChange={(e)=>checkBoxClicked("trading",e.target.checked)}/>
                                <label for="checkbox_id5" className='checkboxLabel'/>
                                <span className='text-sm medium gray-700'>Trading Address</span>
                                <Tooltip icon={'normal'} text={"Address data"}/>
                            </div>

                        </div>
                    </div>


                    {!loading && <div className='sepuButtons'>

                        <div className='sepuButtonsLeft'>
                            <img className='sfrItemIcon' src='/assets/coinsStacked.png' alt='coinStacked'/>
                            <span className='text-xs regular gray-600'>{`${creditsUsed} credits will be used`}</span>
                        </div>

                        <div className='sfpuButtons'>
                            <button className='secondaryButton' onClick={close}>Cancel</button>
                            <div className="tooltip">
                            <button className='primaryButton' onClick={exportFunction} disabled={checkDisabled()}>Export</button>
                                {error!=="" && <span className="tooltiptext button text-sm regular">{error}</span>}
                            </div>
                            
                        </div>
                    </div>} */}
                

                

                {loading && <div className='loader2'>
                    <lottie-player src="https://lottie.host/998ea8e9-6e3b-43b9-8fe1-0bd824113069/xWUkOHakRC.json" background="transparent" speed="1" style={{transform: 'scale(0.7)'}} loop autoplay></lottie-player>
                    </div>
                }
                
            </div>            
        </div>

    )

}