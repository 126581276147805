import { auth } from '..';
import '../styling/login.css'
import '../styling/constants/button.css'
import '../styling/constants/inputs.css'
import React, { useEffect, useState } from 'react';
import axios from "axios";
import { onAuthStateChanged } from 'firebase/auth';
import RevenueFilter from '../components/Search/Advanced/FilterTypes/RevenueFilter';
import SICFilter from '../components/Search/Advanced/FilterTypes/SICFilter';
import { getSicArray } from '../components/Search/Basic/SearchBar';

export default function Admin() {

    const baseURL = process.env.REACT_APP_BASEURL;
    const baseURL2 = process.env.REACT_APP_BASEURL2;

    // const localURL = process.env.REACT_APP_LOCALURL2;

    const [uid,setUID] = useState("") 

    const [checking,setChecking] = useState(false) 
    const [hasAccess,setHasAccess] = useState(false) 


    const [userID,setUserID] = useState("") 
    const [user,setUser] = useState(null) 
    const [loadingUser,setLoadingUser] = useState(false) 
    const [updatingUser,setUpdatingUser] = useState(false) 
    const [updatedUser,setUpdatedUser] = useState(false) 
    const [credits,setCredits] = useState("") 
    const [usedCredits,setUsedCredits] = useState("") 
    const [plan,setPlan] = useState("") 

    const [resettingTrial,setResettingTrial] = useState(false) 


    const [allPlans,setAllPlans] = useState([]) 
    const [selectedPlan,setSelectedPlan] = useState(null) 
    const [updatingPlan,setUpdatingPlan] = useState(false) 
    const [updatedPlan,setUpdatedPlan] = useState(false)
    

    
    const [planCredits,setPlanCredits] = useState("") 
    const [planRate,setPlanRate] = useState("") 
    const [planStripe,setPlanStripe] = useState("") 
    

    const [revenueData,setRevenueData] = useState(null) 
    const [tier,setTier] = useState("") 
    const [sicArray,setSICArray] = useState([]) 

    const [dataFigures,setDataFigures] = useState(null)
    const [dfLoading,setdfLoading] = useState(false)

    const [updateVar,setUpdateVar] = useState(0)

    useEffect(() => {

        
        
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                loadPlans()
                checkIfAdmin(user.uid)
                setUID(user.uid)
            } else {
              setHasAccess(false)
            }
          })
          return () => unsubscribe()
        
      }, []); // eslint-disable-line react-hooks/exhaustive-deps


      
    const loadPlans = () => {
        
        return new Promise(async (resolve, reject) => {
            
            setChecking(true)

            axios.get(baseURL2+'getPlanData',{
                params:{
                    uid: "uid",
                }
            }).then( (response) => {
            
                let plans = response.data.plan
                setAllPlans(plans)
            });
  
        })
  
    }


    const checkIfAdmin = (uid) => {
        
        return new Promise(async (resolve, reject) => {
            
            setChecking(true)

            axios.get(baseURL+'checkIfAdmin',{
                params:{
                    uid: uid,
                }
            }).then( (response) => {
            
                setChecking(false)
                const data = response.data;
                setHasAccess(data.isAdmin)
            });
  
        })
  
    }
    
    const searchForUser = () => {
        
        return new Promise(async (resolve, reject) => {
            
            setUser(null)
            setLoadingUser(true)
            setUpdatedUser(false)

            axios.get(baseURL+'getAccountDetails',{
                params:{
                    uid: userID,
                }
            }).then( (response) => {
            
            
                const data = response.data;
                setUser(data)
                setCredits(data.credits)
                setUsedCredits(data.usedCredits)
                setPlan(data.plan)
                setLoadingUser(false)
            });
  
        })
  
    }

    const updateUser = () => {
        
        return new Promise(async (resolve, reject) => {
            
            setUpdatedUser(false)
            setUpdatingUser(true)

            axios.get(baseURL+'adminUpdateUser',{
                params:{
                    uid:uid,
                    userID: user.uid,
                    credits: credits,
                    usedCredits: usedCredits,
                    plan: plan,
                }
            }).then( (response) => {
        
                user.credits = credits
                user.usedCredits = usedCredits
                user.plan = plan

                setUpdatingUser(false)
                setUpdatedUser(true)
                setUser(user)
            });
  
        })
  
    }


    const resetTrial = () => {
        return new Promise(async (resolve, reject) => {
            
            setResettingTrial(true)

            axios.get(baseURL+'adminResetTrial',{
                params:{
                    uid:uid,
                    userID: user.uid,
                }
            }).then( (response) => {

                setResettingTrial(false)
           
            });
        })
    }


    
    const updatePlan = () => {
        
        return new Promise(async (resolve, reject) => {
            
            setUpdatedPlan(false)
            setUpdatingPlan(true)

            axios.get(baseURL+'adminUpdatePlan',{
                params:{
                    uid:uid,
                    planID:selectedPlan.id,
                    credits: planCredits,
                    stripeID:planStripe,
                    rate:planRate
                }
            }).then( (response) => {
        
                selectedPlan.Credits = planCredits
                selectedPlan.Rate = planRate
                selectedPlan.StripePlan = planStripe

                allPlans.forEach(plan => {
                    if(plan.id === selectedPlan.id)
                    {
                        plan.Credits = planCredits
                        plan.Rate = planRate
                        plan.StripePlan = planStripe
                    }
                });

                
                setUpdatingPlan(false)
                setUpdatedPlan(true)
                setSelectedPlan(selectedPlan)
                setAllPlans(allPlans)
            });
  
        })
  
    }

    
    const searchForFigures = () => {
        
        return new Promise(async (resolve, reject) => {
            
            setDataFigures(null)
            setdfLoading(true)

            axios.post(baseURL+'adminSearchForFigures',{
                
                uid:uid,
                revenueData:revenueData,
                tier:tier,
                sicArray:sicArray.length>0?getSicArray(sicArray[0]):null

                
            }).then( (response) => {
        
                let data = response.data
                setDataFigures(data.data)
                setdfLoading(false)
            });
  
        })
  
    }

    const applyRevenue = (_,value) => {
        setRevenueData(value)
    }

    const getPercentage = (numerator,denominator) => {

        var percentage = ((numerator/denominator)*100)
        
        if(percentage%1!==0 && percentage%1>=.1){
            percentage = percentage.toFixed(2)
        }
        else{
            percentage = Math.floor(percentage)
        }
        
        return ` (${percentage}%)`
    }

        
    const formatNumber = (number) => {

        var str = ""
        str += number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        return str
    }

    
    const applySIC = (filter,value) => {

        setSICArray([value])

    }

    const clearSICs = () => {
        setSICArray([])
    }

    const selectedPlanChanged = (planID) => {

        allPlans.forEach(plan => {
            if(plan.id === planID)
            {
                setSelectedPlan(plan)

                setPlanCredits(plan.Credits)
                setPlanRate(plan.Rate)
                setPlanStripe(plan.StripePlan)
                setUpdateVar(updateVar+1)
            }
        });
    }
   
    
    let filter = {id:"3C3",title:"Turnover", type:"range",subType:"financial",isRevenue:true}
    let filterSIC = {id:"2C",title:"SIC Industry name",type:"multiple",subType:"sic",placeholder:"Enter SIC Code"}


    const testFunction = () => {

        var uid = auth.currentUser.uid;

        return new Promise(async (resolve, reject) => {
            
            axios.get(baseURL2+'sendVerifyEmail',{
                    params:{
                        uid: uid,
                    }
            }).then( (response) => {
            
                const data = response.data;
                resolve(data)
        
            });

        })

    }


    return(
        <div className="column marginTop200 width100 aboveBottom">
            
        {checking && <h2>Checking permissions...</h2>}
        {!checking && !hasAccess && <h2>Permission Denied</h2>}






{/* ------------------------------------------------------------------------------------------------------------- */}
{/*                                   UPDATE ACCOUNT                                                                       */}
{/* ------------------------------------------------------------------------------------------------------------- */}


        {hasAccess && <div className='column width100 gap64 ai-centre'>
        
         <button className='secondaryButton width50' onClick={testFunction}>Test Button</button>



            <div className='column ai-start gap32 width500px'>
                <span className='text-lg semibold gray-700'>Update Account</span>
                <div className='row gap12 ai-centre'>
                    <input className='textInput search text-md regular gray-900' type="text" placeholder={"User ID"} value={userID} onChange={(e) => setUserID(e.target.value)}/>
                    <button className='secondaryButton applyFilterBtn' onClick={searchForUser} disabled={userID===""}>
                        <span className={`text-xs semibold ${userID===""?'gray-300':'primary-700'}`}>Search</span>
                    </button>
                </div>
                {loadingUser && <span>Loading User...</span>}
                {user && <div className='column ai-start gap8 width100'>
                    <div className='row ai-end gap8'>
                        <span className='text-sm semibold'>Name:</span>
                        <span className='text-sm regular'>{user.name}</span>
                    </div>
                    <div className='row ai-end gap8'>
                        <span className='text-sm semibold'>Email:</span>
                        <span className='text-sm regular'>{user.email}</span>
                    </div>
                    <div className='row ai-end gap8'>
                        <span className='text-sm semibold'>Credits:</span>
                        <span className='text-sm regular'>{user.credits}</span>
                    </div>
                    <div className='row ai-end gap8'>
                        <span className='text-sm semibold'>Used Credits:</span>
                        <span className='text-sm regular'>{user.usedCredits}</span>
                    </div>
                    <div className='row ai-end gap8'>
                        <span className='text-sm semibold'>Plan:</span>
                        <span className='text-sm regular'>{user.plan}</span>
                    </div>

                    <div className='row gap12 ai-centre marginTop20 jc-between width100'>
                        <span className='text-sm semibold alignLeft width50'>Set Credits:</span>
                        <input className='textInput text-md regular gray-900' type="number" placeholder={"Credits"} value={credits} onChange={(e) => setCredits(e.target.value)}/>
                    </div>
                    <div className='row gap12 ai-centre jc-between width100'>
                        <span className='text-sm semibold alignLeft width50'>Set Used Credits:</span>
                        <input className='textInput text-md regular gray-900' type="number" placeholder={"Used Credits"} value={usedCredits} onChange={(e) => setUsedCredits(e.target.value)}/>
                    </div>
                    <div className='row gap12 ai-centre jc-between width100'>
                        <span className='text-sm semibold alignLeft width50'>Set Plan:</span>
                        <select className='textInput text-md regular gray-900 pointer' value={plan} onChange={(e) => setPlan(e.target.value)}>
                            <option value="" selected disabled hidden>Choose plan</option>
                            <option value={"free"} >Free</option>
                            <option value={"starterMonth"} >Starter - Monthly</option>
                            <option value={"starterYear"} >Starter - Yearly</option>
                            <option value={"starterContactMonth"} >Starter with Contact - Monthly</option>
                            <option value={"starterContactYear"} >Starter with Contact - Yearly</option>
                            <option value={"plusMonth"} >Plus - Monthly</option>
                            <option value={"plusYear"} >Plus - Yearly</option>

                            <option value={"newMonthly1"} >Tier 1 - Monthly</option>
                            <option value={"newMonthly2"} >Tier 2 - Monthly</option>
                            <option value={"newMonthly3"} >Tier 3 - Monthly</option>
                            <option value={"newMonthly4"} >Tier 4 - Monthly</option>

                            <option value={"newYearly1"} >Tier 1 - Yearly</option>                            
                            <option value={"newYearly2"} >Tier 2 - Yearly</option>
                            <option value={"newYearly3"} >Tier 3 - Yearly</option>
                            <option value={"newYearly4"} >Tier 4 - Yearly</option>

                        </select>
                    </div>

                   

                    <div className='row width100 jc-between ai-centre marginTop20'>
                        {updatingUser && <span>Updating User...</span>}
                        {updatedUser && <span className='success-700 semibold'>User Updated</span>}
                        <button className='secondaryButton applyFilterBtn' onClick={updateUser} disabled={credits===""||usedCredits===""||plan===""}>
                                <span className={`text-xs semibold ${credits===""||usedCredits===""||plan===""?'gray-300':'primary-700'}`}>Update User</span>
                        </button>


                        <div className='column gap12 ai-start jc-start'>
                        <button className='secondaryButton applyFilterBtn' onClick={resetTrial}>
                                <span className={`text-xs semibold primary-700`}>Reset trial</span>
                        </button>
                        {resettingTrial && <span>Resetting trial...</span>}
                        </div>
                        
                    </div>
                    

                </div>}
            </div>
       


{/* ------------------------------------------------------------------------------------------------------------- */}
{/*                                   UPDATE PLAN                                                                       */}
{/* ------------------------------------------------------------------------------------------------------------- */}


        
        <div className='column ai-start gap32 width500px'>
            <span className='text-lg semibold gray-700'>Update Plan</span>
            <div className='row gap12 ai-centre'>
                <select className='textInput text-md regular gray-900 pointer' value={selectedPlan?selectedPlan.id:null} onChange={(e) => selectedPlanChanged(e.target.value)}>
                        <option value="" selected disabled hidden>Choose plan</option>
                        <option value={"free"} >Free</option>
                        <option value={"starterMonth"} >Starter - Monthly</option>
                        <option value={"starterYear"} >Starter - Yearly</option>
                        <option value={"starterContactMonth"} >Starter with Contact - Monthly</option>
                        <option value={"starterContactYear"} >Starter with Contact - Yearly</option>
                        <option value={"plusMonth"} >Plus - Monthly</option>
                        <option value={"plusYear"} >Plus - Yearly</option>
                    </select>
            </div>

            {selectedPlan && <div className='column ai-start gap8 width100'>
                <div className='row ai-end gap8'>
                    <span className='text-sm semibold'>Name:</span>
                    <span className='text-sm regular'>{selectedPlan.Name}</span>
                </div>
                <div className='row ai-end gap8'>
                    <span className='text-sm semibold'>Credits:</span>
                    <span className='text-sm regular'>{selectedPlan.Credits}</span>
                </div>
                <div className='row ai-end gap8'>
                    <span className='text-sm semibold'>Rate (per month):</span>
                    <span className='text-sm regular'>{selectedPlan.Rate}</span>
                </div>
                <div className='row ai-end gap8'>
                    <span className='text-sm semibold'>Stripe Plan:</span>
                    <span className='text-sm regular'>{selectedPlan.StripePlan}</span>
                </div>
                

                <div className='row gap12 ai-centre marginTop20 jc-between width100'>
                    <span className='text-sm semibold alignLeft width50'>Set Credits:</span>
                    <input className='textInput text-md regular gray-900' type="number" placeholder={"Credits"} value={planCredits} onChange={(e) => setPlanCredits(e.target.value)}/>
                </div>
                <div className='row gap12 ai-centre jc-between width100'>
                    <span className='text-sm semibold alignLeft width50'>Set Monthly Rate:</span>
                    <input className='textInput text-md regular gray-900' type="number" placeholder={"Rate"} value={planRate} onChange={(e) => setPlanRate(e.target.value)}/>
                </div>
                <div className='row gap12 ai-centre jc-between width100'>
                    <span className='text-sm semibold alignLeft width50'>Set Stripe Plan:</span>
                    <input className='textInput text-md regular gray-900' type="text" placeholder={"Stripe Plan"} value={planStripe} onChange={(e) => setPlanStripe(e.target.value)}/>
                </div>
                

               

                <div className='row width100 jc-between ai-centre marginTop20'>
                    {updatingPlan && <span>Updating Plan...</span>}
                    {updatedPlan && <span className='success-700 semibold'>Plan Updated</span>}
                    <button className='secondaryButton applyFilterBtn' onClick={updatePlan} disabled={planCredits===""||planRate===""||planStripe===""}>
                            <span className={`text-xs semibold ${planCredits===""||planRate===""||planStripe===""?'gray-300':'primary-700'}`}>Update Plan</span>
                    </button>
                    
                </div>
                

            </div>}
        </div>

{/* ------------------------------------------------------------------------------------------------------------- */}
{/*                                   GET FIGURES                                                                       */}
{/* ------------------------------------------------------------------------------------------------------------- */}

            <div className='column ai-start gap32 width500px'>
                <span className='text-lg semibold gray-700'>Get Figures</span>

                <RevenueFilter filter={filter} addedFilters={[]} apply={applyRevenue} hideApply={true}/>


                <div className="searchBubble industry admin">
            
                    <div className='searchBubbleTitleRow'>
                        <span className='text-sm medium gray-700'>Industry</span>
                        <span className='text-sm medium primary-700 pointer' onClick={clearSICs}>Clear</span>
                    </div>
                    <SICFilter filter={filterSIC} addedFilters={sicArray} apply={applySIC} clear={clearSICs}/>

                </div>


                <div className='row gap64 ai-centre'>
                    <select className='textInput text-md regular gray-900 pointer' value={tier} onChange={(e) => setTier(e.target.value)}>
                        <option value="" selected disabled hidden>Choose Tier</option>
                        <option value={"All"}>All</option>
                        <option value={"Basic"}>Basic</option>
                        <option value={"Premium"}>Premium</option>
                    </select>
                    <button className='secondaryButton applyFilterBtn' onClick={searchForFigures} disabled={!revenueData}>
                        <span className={`text-xs semibold ${!revenueData?'gray-300':'primary-700'}`}>Search</span>
                    </button>
                </div>
                
                {dfLoading && <span>Loading Data...</span>}
                {dataFigures && <div className='column ai-start gap8 width100'>
                    <div className='row ai-end gap8 width75 jc-between'>
                        <span className='text-sm semibold'>Amount In Tier:</span>
                        <span className='text-sm regular'>{formatNumber(dataFigures.amountInTier)}</span>
                    </div>
                    <div className='row ai-end gap8 width75 jc-between'>
                        <span className='text-sm semibold'>Amount Companies Filtered:</span>
                        <span className='text-sm regular'>{formatNumber(dataFigures.amountCompanies)} {getPercentage(dataFigures.amountCompanies,dataFigures.amountInTier)}</span>
                    </div>
                    <div className='row ai-end gap8 width75 jc-between'>
                        <span className='text-sm semibold'>With Actual Profit:</span>
                        <span className='text-sm regular'>{formatNumber(dataFigures.compActualProfit)} {getPercentage(dataFigures.compActualProfit,dataFigures.amountCompanies)}</span>
                    </div>
                    <div className='row ai-end gap8 width75 jc-between'>
                        <span className='text-sm semibold'>With Estimated Profit:</span>
                        <span className='text-sm regular'>{formatNumber(dataFigures.amountCompanies-dataFigures.compActualProfit)} {getPercentage(dataFigures.amountCompanies-dataFigures.compActualProfit,dataFigures.amountCompanies)}</span>
                    </div>
                    <div className='row ai-end gap8 width75 jc-between'>
                        <span className='text-sm semibold'>Profitable:</span>
                        <span className='text-sm regular'>{formatNumber(dataFigures.compProfitable)} {getPercentage(dataFigures.compProfitable,dataFigures.amountCompanies)}</span>
                    </div>
                    <div className='row ai-end gap8 width75 jc-between'>
                        <span className='text-sm semibold'>Leverageable:</span>
                        <span className='text-sm regular'>{formatNumber(dataFigures.compLeverageable)} {getPercentage(dataFigures.compLeverageable,dataFigures.amountCompanies)}</span>
                    </div>
                    <div className='row ai-end gap8 width75 jc-between'>
                        <span className='text-sm semibold'>Simple Ownership:</span>
                        <span className='text-sm regular'>{formatNumber(dataFigures.compSimple)} {getPercentage(dataFigures.compSimple,dataFigures.amountCompanies)}</span>
                    </div>
                    <div className='row ai-end gap8 width75 jc-between'>
                        <span className='text-sm semibold'>Established:</span>
                        <span className='text-sm regular'>{formatNumber(dataFigures.compEstablished)} {getPercentage(dataFigures.compEstablished,dataFigures.amountCompanies)}</span>
                    </div>
                    <div className='row ai-end gap8 width75 jc-between'>
                        <span className='text-sm semibold'>Retiring Owner:</span>
                        <span className='text-sm regular'>{formatNumber(dataFigures.compRetiring)} {getPercentage(dataFigures.compRetiring,dataFigures.amountCompanies)}</span>
                    </div>
                    <div className='row ai-end gap8 width75 jc-between'>
                        <span className='text-sm semibold'>With Website:</span>
                        <span className='text-sm regular'>{formatNumber(dataFigures.compWebsite)} {getPercentage(dataFigures.compWebsite,dataFigures.amountCompanies)}</span>
                    </div>
                    <div className='row ai-end gap8 width75 jc-between'>
                        <span className='text-sm semibold'>With Trading Address:</span>
                        <span className='text-sm regular'>{formatNumber(dataFigures.compTradingAddress)} {getPercentage(dataFigures.compTradingAddress,dataFigures.amountCompanies)}</span>
                    </div>
                    <div className='row ai-end gap8 width75 jc-between'>
                        <span className='text-sm semibold'>With PSC Email:</span>
                        <span className='text-sm regular'>{formatNumber(dataFigures.compEmail)} {getPercentage(dataFigures.compEmail,dataFigures.amountCompanies)}</span>
                    </div>
                    <div className='row ai-end gap8 width75 jc-between'>
                        <span className='text-sm semibold'>With PSC LinkedIn:</span>
                        <span className='text-sm regular'>{formatNumber(dataFigures.compLinkedIn)} {getPercentage(dataFigures.compLinkedIn,dataFigures.amountCompanies)}</span>
                    </div>

                    <div className='row ai-end gap8 width75 jc-between marginTop20'>
                        <span className='text-sm semibold'>Amount PSCs:</span>
                        <span className='text-sm regular'>{formatNumber(dataFigures.amountPSCs)}</span>
                    </div>
                    <div className='row ai-end gap8 width75 jc-between'>
                        <span className='text-sm semibold'>With Email:</span>
                        <span className='text-sm regular'>{formatNumber(dataFigures.pscEmail)} {getPercentage(dataFigures.pscEmail,dataFigures.amountPSCs)}</span>
                    </div>
                    <div className='row ai-end gap8 width75 jc-between'>
                        <span className='text-sm semibold'>With LinkedIn:</span>
                        <span className='text-sm regular'>{formatNumber(dataFigures.pscLinkedIn)} {getPercentage(dataFigures.pscLinkedIn,dataFigures.amountPSCs)}</span>
                    </div>
                </div>}    
            </div>
            
        </div>}
            
            

        </div>
    )

}