import "../../../../styling/filters.css";
import React, { useEffect, useState, useContext } from "react";
import Tooltip from "../../../constants/Tooltip";
import { ParentContext } from "../../../constants/ParentContext";
import { Mixpanel } from "../../../Mixpanel/Mixpanel";

export default function RevenueFilter({
  filter,
  addedFilters,
  apply,
  clearCount,
  hideApply,
}) {
  // #region CONSTANTS & STATE VARIABLES

  let user = JSON.parse(localStorage.getItem("user"))

  const [min, setMin] = useState("");
  const [max, setMax] = useState("");
  const [anyValueEntered, setAnyValueEntered] = useState(false);
  const [checkedEstimatedRevenue, setCheckedEstimatedRevenue] = useState(true);
  const context = useContext(ParentContext);
  const isBiz = context.isBiz4Biz || context.isHarbour ? false : true;
  const options = [
    { value: 250000, title: "£250K" },
    { value: 500000, title: "£500K" },
    { value: 1000000, title: "£1M" },
    { value: 2500000, title: "£2.5M" },
    { value: 5000000, title: "£5M" },
    { value: 7500000, title: "£7.5M" },
    { value: 10000000, title: "£10M" },
    { value: 12500000, title: "£12.5M" },
    { value: 15000000, title: "£15M" },
    { value: 20000000, title: "£20M" },
    { value: 25000000, title: "£25M" },
    { value: 30000000, title: "£30M" },
    { value: 35000000, title: "£35M" },
    { value: 40000000, title: "£40M" },
    { value: 45000000, title: "£45M" },
    { value: 50000000, title: "£50M" },
    { value: 60000000, title: "£60M" },
    { value: 70000000, title: "£70M" },
    { value: 80000000, title: "£80M" },
    { value: 90000000, title: "£90M" },
    { value: 100000000, title: "£100M" },
  ];

  // #endregion

  useEffect(() => {
    addedFilters.forEach((element) => {
      if (element.id === filter.id) {
        setMin(element.value.min);
        setMax(element.value.max);
        setCheckedEstimatedRevenue(element.value.approx);
      }
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (clearCount > 0) {
      setMin("");
      setMax("");
      setCheckedEstimatedRevenue(false);
    }
  }, [clearCount]);

  useEffect(() => {}, [max, min]);

  const changeMin = (val) => {
    setMin(val);
    if (
      ((val !== null && val !== "") || (max !== null && max !== "")) &&
      hideApply
    ) {
      let value = { min: val, max: max, approx: checkedEstimatedRevenue };
      apply(filter, value);
    }
  };

  const changeMax = (val) => {
    setMax(val);
    if (
      ((min !== null && min !== "") || (val !== null && val !== "")) &&
      hideApply
    ) {
      let value = { min: min, max: val, approx: checkedEstimatedRevenue };
      apply(filter, value);
    }
  };

  useEffect(() => {
        if(anyValueEntered)
        {
            Mixpanel.track("FeatureUsage", {
                 $name: user?.name, 
                 $email: user?.email, 
                 "Plan": user?.plan,
                 "User Type": "Buyer",
                 "User ID": user?.uid,
                 "Date": new Date().toISOString(),
                 "Feature":"Applied Revenue Filter"
              });
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[anyValueEntered])

  // #region SHOW COMPONENTS

  const addOptions = (type) => {
    let compArray = [];
    options.forEach((element) => {
      compArray.push(
        <option
          value={element.value}
          title={element.title}
          disabled={isOptionDisabled(type, element.value)}
          key={element.title}
        >
          {element.title}{" "}
        </option>
      );
    });
    return compArray;
  };

  const isOptionDisabled = (type, value) => {
    if (type === "max" && min !== "" && value <= +min) {
      console.log("TRUE");
      return true;
    } else if (type === "min" && max !== "" && value >= +max) {
      return true;
    } else {
      return false;
    }
  };

  // #endregion

  // #region WEB REQUESTS

  // #endregion

  // #region BUTTONS CLICKED
  const applyFilter = () => {
    let value = { min: min, max: max, approx: checkedEstimatedRevenue };
    apply(filter, value);
  };

  //   const minChanged = (amount) => {

  //     if(amount>=0 || amount==='')
  //     {
  //         if(filter.max && amount>filter.max){amount = filter.max}
  //         setMin(amount)

  //         // if(+max<+amount){
  //         //     console.log(amount,max)
  //         //     setMax(amount)
  //         // }
  //     }
  //   }

  //   const maxChanged = (amount) => {

  //     if(amount>=0 || amount==='')
  //     {
  //         if(filter.max && amount>filter.max){amount = filter.max}
  //         setMax(amount)

  //         // if(+min>+amount){

  //         //     setMin(amount)
  //         // }
  //     }
  //   }

  const revenueClick = () => {
    if (hideApply) {
      let value = { min: min, max: max, approx: !checkedEstimatedRevenue };
      apply(filter, value);
    }

    setCheckedEstimatedRevenue(!checkedEstimatedRevenue);
  };

  const isDisabled = () => {
    if ((min === null || min === "") && (max === null || max === "")) {
      return true;
    } else if (
      min !== null &&
      min !== "" &&
      max !== null &&
      max !== "" &&
      +max < +min
    ) {
      return true;
    } else {
      return false;
    }
  };

  // #endregion

  // #region OTHER

  // #endregion

  return (
    <div className="rangeFilter">
      <div className="revenueInputRow">
        <select
          className="textInput text-md regular gray-900 pointer"
          value={min}
          onChange={(e) => {changeMin(e.target.value);setAnyValueEntered(true)}}
        >
          <option value="" disabled hidden>
            Select Min
          </option>
          {addOptions("min")}
        </select>
        {/* <input className={`textInput ${filter.subType==="financial"?"currency":""} text-md ${min===""?"regular":"medium"} gray-900`} type="number" placeholder={"Min"} value={min} onChange={(e) => minChanged(e.target.value)} min={0} max={filter.max?filter.max:null}/> */}
        <span className="text-sm regular gray-500">to</span>
        {/* <input className={`textInput ${filter.subType==="financial"?"currency":""} text-md ${max===""?"regular":"medium"} gray-900`} type="number" placeholder={"Max"} value={max} onChange={(e) => maxChanged(e.target.value)} min={min?min:0} max={filter.max?filter.max:null}/> */}
        <select
          className="textInput text-md regular gray-900 pointer"
          value={max}
          onChange={(e) => {changeMax(e.target.value);setAnyValueEntered(true)}}
        >
          <option value="" disabled hidden>
            Select Max
          </option>
          {addOptions("max")}
        </select>
      </div>
      <div className="revenueInputRow">
        <input
          id="checkbox_id"
          className="input-md checkbox"
          type="checkbox"
          checked={checkedEstimatedRevenue}
          onChange={revenueClick}
        />
        <label htmlFor="checkbox_id" className="checkboxLabel" />
        {/* <img className='revEstIcon' src='/assets/profitable.png'/> */}
        <span className="text-sm medium gray-700">
          ~ Estimated Bizcrunch Revenue
        </span>
        <Tooltip
          icon={"normal"}
          text={
            "Filter companies by estimated revenue when actual figures are unreported"
          }
        />
      </div>
      {!hideApply && (
        <button
          className="secondaryButton applyFilterBtn"
          disabled={isDisabled()}
          onClick={applyFilter}
        >
          <img
            className="secondaryButtonIcon"
            src={
              isDisabled()
                ? "/assets/grey-plus.png"
                : isBiz
                ? "/assets/red-plus.png"
                : "/assets/blue-plus.png"
            }
            alt="plus"
          />
          <span
            className={`text-xs semibold ${
              isDisabled() ? "gray-300" : "primary-700"
            }`}
          >
            Apply filter
          </span>
        </button>
      )}
    </div>
  );
}
