import { auth } from "..";
import "../styling/search.css";
import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import SearchBar from "../components/Search/Basic/SearchBar";
import axios from "axios";
import Tooltip from "../components/constants/Tooltip";
import SearchResultRow from "../components/Search/SearchResultRow";
// import NotEnoughCreditsPopUp from '../components/popUps/NotEnoughCreditsPopUp';
import TutorialBubble from '../components/Search/Basic/Search Bubbles/TutorialBubble';
import CreditBreakdownPopUp from '../components/popUps/CreditBreakdownPopUp';
import { Mixpanel } from '../components/Mixpanel/Mixpanel';
export default function Search({user,creditsP,updateCredits,saveToast}) {


// #region CONSTANTS & STATE VARIABLES
  const navigate = useNavigate()
  const baseURL2 = process.env.REACT_APP_BASEURL2;

  const [searched,setSearched] = useState(false) 
  const [loading,setLoading] = useState(false) 
  const [results,setResults] = useState([])
  const [shownResults,setShownResults] = useState([])

  const [selectedBusinesses,setSelectedBusinesses] = useState([])
  const [selectedBusinessesExpanded,setSelectedBusinessesExpanded] = useState([])
  const [selectAllCheckbox,setSelectAllCheckbox] = useState(false)

//   const [pCount,setPCount] = useState(0)
  const [PSCs,setPSCs] = useState([])
  const [appliedTags,setAppliedTags] = useState([])

  const [exportCount,setExportCount] = useState(0)
  const [clearCount,setClearCount] = useState(0)
  const [closeCount,setCloseCount] = useState(0)
  const [saveFilterCount,setSaveFilterCount] = useState(0)
  
  const [appliedSort, setAppliedSort] = useState("")
  const [sortHighest, setSortHighest] = useState(false)

  const [credits,setCredits] = useState(creditsP)

  const [freeMode,setFreeMode] = useState(false)
  const [plan,setPlan] = useState("free")
//   const [showCreditsPopUp,setShowCreditsPopUp] = useState(false)

//   const [exportBalance, setExportBalance] = useState(0)

  const [currentPage, setCurrentPage] = useState(1)

  const [freeBannerOpen, setFreeBannerOpen] = useState(true)
  const [tutorialStep, setTutorialStep] = useState("")

  const [showOptionOpen, setShowOptionOpen] = useState(false)
  const [showOptionSelected, setShowOptionSelected] = useState("all")

  const [updateVar, setUpdateVar] = useState(0)

  const [showCreditBreakdown, setShowCreditBreakdown] = useState(false)
  const [hideExported, setHideExported] = useState(true)

  const [showExportPopUp, setShowExportPopUp] = useState(false)

  let userData = JSON.parse(localStorage.getItem("user"))

  const topRowRef = useRef()
  const primary600 = getComputedStyle(document.body).getPropertyValue('--primary-600');
// #endregion


useEffect(() => {
    setCredits(creditsP)
},[creditsP])


useEffect(() => {

    console.log("USER search",user)

    if(user)
    {
        if (!auth.currentUser.emailVerified && window.location === window.parent.location) {
            navigate(`../verify-email`,{replace:true})
        }
        let plan = user?user.plan:"free"
        // setFreeMode(plan==="free")
        setFreeMode(false)
        setPlan(plan)
        Mixpanel.identify(user.uid);
        Mixpanel.people.set({
            $name: user.name,
            $email: user.email,
            plan: user.plan,
        });
    }
  }, [user, navigate]);

  useEffect(() => {
    if (showOptionSelected === "all") {
      setShownResults(results);
    } else if (showOptionSelected === "standard") {
      setShownResults(results.filter((x) => !x.premium));
    } else if (showOptionSelected === "premium") {
      setShownResults(results.filter((x) => x.premium));
    }
    setUpdateVar(updateVar + 1);

    console.log("SHOWN RESULTS LENGTH", shownResults.length);
    setCurrentPage(1);
  }, [results, showOptionSelected]); // eslint-disable-line react-hooks/exhaustive-deps

  // #region SHOW COMPONENTS

  const getAppliedTags = () => {
    let compArray = [];

    appliedTags.forEach((element) => {
      var src;
      if (element === "Leverageable") {
        src = "assets/lowDebt.png";
      }
      if (element === "Retiring Owner") {
        src = "assets/seniorfounder.png";
      }
      if (element === "Profitable") {
        src = "assets/profitable.png";
      }
      if (element === "Simple ownership") {
        src = "/assets/simpleownerstructure.png";
      }
      if (element === "Established") {
        src = "assets/established.png";
      }

      compArray.push(
        <div className={`smartAcquireFilter pink`} key={element}>
          <div className="safLeft">
            <span className={`safTitle text-xs semibold gray-700`}>
              {element}
            </span>
            <Tooltip icon={"pink"} text={element} />
          </div>
          <img className="safIcon" src={src} alt="icon" />
        </div>
      );
    });

    return compArray;
  };

  // #endregion

  // #region WEB REQUESTS
  const updateCreditsDB = (used) => {
    axios
      .post(baseURL2 + "updateCreditsDB", {
        uid: auth.currentUser.uid,
        used: used,
      })
      .then((response) => {
        const data = response.data;
        console.log("DATA", data);
      });
  };

  // #endregion

  // #region BUTTONS CLICKED
  const exportClicked = () => {
    if (tutorialStep === "") {
      var selectedCompanies = results;

      if (selectedBusinesses.length > 0) {
        console.log("SBL", selectedBusinesses);
        selectedCompanies = [];
        results.forEach((element) => {
          if (selectedBusinesses.includes(element.number)) {
            selectedCompanies.push(element);
          }
        });
      }

      let user = JSON.parse(localStorage.getItem("user"));
      // var alreadyExported = 0
      var companiesAlreadyExported = [];
      if (user.exports) {
        selectedCompanies.forEach((element) => {
          let match = user.companiesExported.filter(
            (x) =>
              x.CompanyNumber === element.number &&
              new Date(element.updated).getTime() <
                new Date(x.ExportTime).getTime()
          );
          if (match.length > 0) {
            companiesAlreadyExported.push(element.number);
          }
        });
        // alreadyExported = Object.keys(companiesAlreadyExported).length
      }

      //Don't show popup that says they don't have enough credits

      // if(selectedCompanies.length-alreadyExported>credits)
      // {
      //     setExportBalance(selectedCompanies.length-alreadyExported)
      //     setShowCreditsPopUp(true)
      // }
      // else{
      setExportCount(exportCount + 1);
      // }
    }
  };

  const exportSuccessful = (exported) => {
    Mixpanel.track("Export Completed", {
      $name: userData?.name, 
      $email: userData?.email, 
      "Plan": userData?.plan,
      "User Type": "Buyer",
      "User ID": userData?.uid,
      "Date": new Date().toISOString()
    });

    updateCreditsDB(exported)
    setCredits(credits-exported)
    updateCredits(credits-exported)

    //Update Locally
    let user = JSON.parse(localStorage.getItem("user"));
    user.usedCredits = user.usedCredits + exported;
    localStorage.setItem("user", JSON.stringify(user));
  };

  const saveFilterClicked = () => {
    Mixpanel.track("Save Filter Clicked", {
      $name: userData?.name, 
      $email: userData?.email, 
      "Plan": userData?.plan,
      "User Type": "Buyer",
      "User ID": userData.uid,
      "Date": new Date().toISOString(),
    });
    console.log("SFC");
    setSaveFilterCount(saveFilterCount + 1);
  };

  const clearClicked = () => {
    setClearCount(clearCount + 1);
    setSearched(false);
  };

  //   const changePage = (x) => {
  //     setCurrentPage(x)
  //     topRowRef.current?.scrollIntoView({ behavior: 'smooth' });
  //   }

  const upgrade = () => {
    localStorage.setItem("upgradePlan", true);
    Mixpanel.track("Feature Usage", {
      $name: userData?.name, 
      $email: userData?.email, 
      "Plan": userData?.plan,
      "User Type": "Buyer",
      "User ID": userData.uid,
      "Date": new Date().toISOString(),
      "Feature": "Upgrade Plan Button"
    });
    navigate(`../billing`, { replace: true, state: { upgradePlan: true } });
  };

  const sortResults = (sort) => {
    let sorted = [];

    if (sort === "revenue") {
      sorted = results.sort((a, b) => b.turnover - a.turnover);
      if (sort === appliedSort && sortHighest) {
        sorted = results.sort((a, b) => a.turnover - b.turnover);
      }
    } else if (sort === "netProfit") {
      sorted = results.sort((a, b) => b.netProfit - a.netProfit);
      if (sort === appliedSort && sortHighest) {
        sorted = results.sort((a, b) => a.netProfit - b.netProfit);
      }
    } else if (sort === "netProfitPercentage") {
      sorted = results.sort(
        (a, b) => b.netProfitPercentage - a.netProfitPercentage
      );
      if (sort === appliedSort && sortHighest) {
        sorted = results.sort(
          (a, b) => a.netProfitPercentage - b.netProfitPercentage
        );
      }
    } else if (sort === "debtRatio") {
      sorted = results.sort((a, b) => b.debtRatio - a.debtRatio);
      if (sort === appliedSort && sortHighest) {
        sorted = results.sort((a, b) => a.debtRatio - b.debtRatio);
      }
    } else if (sort === "emails") {
      sorted = results.sort((a, b) => b.emailCount - a.emailCount);
      if (sort === appliedSort && sortHighest) {
        sorted = results.sort((a, b) => a.emailCount - b.emailCount);
      }
    }

    if (sort !== appliedSort) {
      setAppliedSort(sort);
      setSortHighest(true);
    } else {
      setSortHighest(!sortHighest);
    }
    setResults(sorted);
  };

  // #endregion

  // #region OTHER

  // #endregion

const searching = () => {
    console.log("LOADING")
    setLoading(true)
    setSearched(true)
    Mixpanel.track("Search Completed", {
      $name: userData?.name, 
      $email: userData?.email, 
      "Plan": userData?.plan,
      "User Type": "Buyer",
      "User ID": userData.uid,
      "Date": new Date().toISOString()
    }
    );
}

  const resultsReceived = (res, pscList, tagList, pCount) => {
    setLoading(false);
    setPSCs(pscList);
    setResults(res);
    // setPCount(pCount)
    setCurrentPage(1);
    setAppliedTags(tagList);
    setAppliedSort("emails");

    let user = JSON.parse(localStorage.getItem("user"));

    if (!user.searchPerformed) {
      setTutorialStep("resultRow");
      user.searchPerformed = true;
      localStorage.setItem("user", JSON.stringify(user));
    }

    var arr = [];
    var arrExpanded = [];

    res.forEach((element) => {
      if (!element.premium || user.exports.length > 0 || user.plan !== "free") {
        arr.push(element.number);
        arrExpanded.push(element);
      }
    });

    setSelectAllCheckbox(true);
    setSelectedBusinesses(arr);
    setSelectedBusinessesExpanded(arrExpanded);
  };

  const showResults = () => {
    let compArray = [];
    compArray.push(
      // <thead>
      <tr className="text-xs medium gray-600 noselect" key={0}>
        <th style={{ width: "3%" }} className="text-xs medium gray-600">
          <input
            id={`checkbox_id_all`}
            className="checkbox billing"
            type="checkbox"
            checked={selectAllCheckbox}
            onChange={(e) => allBusinessCheckboxChanged(e.target.checked)}
          />
          <label htmlFor={`checkbox_id_all`} className="checkboxLabel" />
        </th>
        <th style={{ width: "30%" }} className="text-xs medium gray-600">
          Company
        </th>
        <th style={{ width: "12%" }} className="text-xs medium gray-600">
          Industry
        </th>
        {/* <th className='alignRight' style={{width:'15%'}}>
                    <span className='text-xs medium gray-600'>Revenue</span>
                </th> */}
        <th style={{ width: "12%" }}>
          <div className="row jc-end gap8 alignRight">
            <span
              className="text-xs medium gray-600 pointer row ai-centre"
              onClick={() => sortResults("revenue")}
            >
              Revenue{" "}
              {appliedSort === "revenue" ? (
                <img
                  className="sicArrow"
                  src={
                    sortHighest
                      ? "/assets/chevron-down.png"
                      : "/assets/chevron-up.png"
                  }
                  alt="sortArrow"
                />
              ) : (
                ""
              )}
            </span>
            <Tooltip
              icon={"question"}
              text={
                "Filter companies by estimated revenue when actual figures are unreported"
              }
            />
          </div>
        </th>

        <th
          className="text-xs medium gray-600 alignRight pointer"
          onClick={() => sortResults("netProfit")}
        >
          {" "}
          <span className="row ai-centre jc-end">
            Net Profit
            {appliedSort === "netProfit" ? (
              <img
                className="sicArrow"
                src={
                  sortHighest
                    ? "/assets/chevron-down.png"
                    : "/assets/chevron-up.png"
                }
                alt="sortArrow"
              />
            ) : (
              ""
            )}
          </span>
        </th>
        <th
          className="text-xs medium gray-600 alignRight pointer"
          onClick={() => sortResults("netProfitPercentage")}
        >
          <span className="row ai-centre jc-end">
            Net Profit %{" "}
            {appliedSort === "netProfitPercentage" ? (
              <img
                className="sicArrow"
                src={
                  sortHighest
                    ? "/assets/chevron-down.png"
                    : "/assets/chevron-up.png"
                }
                alt="sortArrow"
              />
            ) : (
              ""
            )}
          </span>
        </th>

        {/* <th className='text-xs medium gray-600 alignRight'>Debt Ratio</th> */}

        <th className="shareholders" style={{ width: "9%" }}>
          <div className="row jc-end gap8">
            <span
              className="text-xs medium gray-600 alignRight pointer row ai-centre"
              onClick={() => sortResults("debtRatio")}
            >
              Debt Ratio
              {appliedSort === "debtRatio" ? (
                <img
                  className="sicArrow"
                  src={
                    sortHighest
                      ? "/assets/chevron-down.png"
                      : "/assets/chevron-up.png"
                  }
                  alt="sortArrow"
                />
              ) : (
                ""
              )}
            </span>
            <Tooltip
              icon={"question"}
              text={
                "Filter by the amount of debt vs. asset value a company has"
              }
            />
          </div>
        </th>

        <th className="shareholders" style={{ width: "9%" }}>
          <div className="row jc-start gap8">
            <span className="text-xs medium gray-600">Shareholders</span>
            <Tooltip
              icon={"question"}
              text={
                "Persons of significant control that own at least 25% of shares"
              }
            />
          </div>
        </th>

        <th
          className="text-xs medium gray-600 alignRight pointer row ai-centre jc-end"
          onClick={() => sortResults("emails")}
        >
          Contact Data
          {appliedSort === "emails" ? (
            <img
              className="sicArrow"
              src={
                sortHighest
                  ? "/assets/chevron-down.png"
                  : "/assets/chevron-up.png"
              }
              alt="sortArrow"
            />
          ) : (
            ""
          )}
        </th>
      </tr>
      // </thead>
    );

    var start = (currentPage - 1) * 50;
    var end = start + 50;
    var index = 0;

    shownResults.forEach((element) => {
      var thisIndex = index;
      if (thisIndex >= start && thisIndex < end) {
        compArray.push(
          <SearchResultRow
            element={element}
            PSCs={PSCs}
            freeMode={freeMode}
            plan={plan}
            checked={selectedBusinesses}
            onCheck={businessCheckboxChanged}
            showTutorial={thisIndex === 0 && tutorialStep === "resultRow"}
            closeTutorial={() => setTutorialStep("")}
            nextTutorial={() => setTutorialStep("export")}
            key={element.name}
          />
        );
      }
      index++;
    });

    return compArray;
  };

  // const chosenToShow = (premium) =>
  // {

  //     if(showOptionSelected === "all"){return true}
  //     else if(showOptionSelected === "standard" && !premium){return true}
  //     else if(showOptionSelected === "premium" && premium){return true}
  //     else{
  //         return false
  //     }
  // }

  useEffect(() => {
    setSelectAllCheckbox(false);
  }, [showOptionSelected]); // eslint-disable-line react-hooks/exhaustive-deps

  const allBusinessCheckboxChanged = (checked) => {
    let user = JSON.parse(localStorage.getItem("user"));

    var newList;

    if (showOptionSelected === "all") {
      newList = [];
    } else if (showOptionSelected === "standard") {
      newList = selectedBusinessesExpanded.filter((x) => x.premium);
    } else if (showOptionSelected === "premium") {
      newList = selectedBusinessesExpanded.filter((x) => !x.premium);
    }

    let selectedBusinesses = [];
    newList.forEach((element) => {
      selectedBusinesses.push(element.number);
    });

    if (checked) {
      var arr = [];
      var arr2 = [];
      results.forEach((element) => {
        if (
          (showOptionSelected === "all" &&
            (!element.premium ||
              user.exports.length > 0 ||
              user.plan !== "free")) ||
          (showOptionSelected === "standard" && !element.premium) ||
          (showOptionSelected === "premium" &&
            element.premium &&
            (user.exports.length > 0 || user.plan !== "free"))
        ) {
          arr.push(element.number);
          arr2.push(element);
        }
      });

      selectedBusinesses.push(...arr);
      newList.push(...arr2);
      setSelectedBusinesses(selectedBusinesses);
      setSelectedBusinessesExpanded(newList);
      setSelectAllCheckbox(true);
    } else {
      setSelectedBusinesses(selectedBusinesses);
      setSelectedBusinessesExpanded(newList);
      setSelectAllCheckbox(false);
    }
  };

  const businessCheckboxChanged = (comp, checked) => {
    if (checked) {
      selectedBusinesses.push(comp.number);
      selectedBusinessesExpanded.push(comp);
      setSelectedBusinessesExpanded(selectedBusinessesExpanded);
    } else {
      const index = selectedBusinesses.indexOf(comp.number);
      selectedBusinesses.splice(index, 1);

      setSelectedBusinessesExpanded(
        selectedBusinessesExpanded.filter((x) => x.number !== comp.number)
      );

      console.log("KSG LENGTH", selectedBusinessesExpanded.length);
    }

    setSelectedBusinesses(selectedBusinesses);

    console.log("KSG 2 LENGTH", selectedBusinessesExpanded.length);
  };

  // const getSICTitle = (code,sicJSON) => {

  //     var foundSIC = false

  //     for(const element of sicJSON)
  //     {
  //         if(element.sicCode === code)
  //         {
  //             foundSIC = true
  //             return {found:true,title:element.title}
  //         }
  //         else{
  //             if(element.subValues.length>0)
  //             {
  //                 let res = getSICTitle(code,element.subValues)
  //                 if(res.found){
  //                     foundSIC = true
  //                     return {found:true,title:res.title}
  //                 }
  //             }
  //         }

  //     };

  //     if(!foundSIC)
  //     {
  //         return {found:false}
  //     }

  // }

  // const getPSCStr = (number) =>
  // {
  //     let people = []
  //     PSCs.forEach(element => {
  //         if(element.number === number)
  //         {
  //             people.push(element)
  //         }
  //     });

  //     if(people.length === 0){return ""}
  //     else if(people.length === 1){
  //         return people[0].firstName + " " + people[0].surname[0]
  //     }
  //     else{
  //         return people[0].firstName + " " + people[0].surname[0] + " + " + (people.length-1) + " more"
  //     }

  // }

  // const getContactDetails = (number) => {

  //     let peopleCount = 0
  //     let emailCount = 0

  //     PSCs.forEach(element => {
  //         if(element.number === number)
  //         {
  //             peopleCount++
  //             if(element.email !== "" && element.email !== undefined && element.email !== "undefined" && element.email !== "null"){emailCount++}
  //         }
  //     });

  //     return (
  //         <div className='contactDataRow'>
  //                         <div className='contactDataInnerRow'>
  //                             <img className='cdIcon' src={emailCount===peopleCount?'/assets/mail.png':'/assets/mail-disable.png'}/>
  //                             <span className={`${emailCount===peopleCount?'gray-600':'gray-400'}`}>{emailCount}</span>
  //                         </div>
  //                         {/* <div className='contactDataInnerRow'>
  //                             <img className='cdIcon' src='/assets/linkedin_grey.png'/>
  //                             <span>1</span>
  //                         </div> */}
  //                     </div>
  //     )

  // }

  const screenClicked = (e) => {
    setCloseCount(closeCount + 1);
  };

  const getResultsPageStr = () => {
    var str = "";

    str += (currentPage - 1) * 50 + 1;
    str += "-";
    str +=
      (currentPage - 1) * 50 + 50 > shownResults.length
        ? shownResults.length
        : (currentPage - 1) * 50 + 50;
    str += " of " + shownResults.length;
    return str;
  };

  const calculateCredits = () => {
    var credits = 0;
    selectedBusinessesExpanded.forEach((company) => {
      console.log("COMP", company);
      if (company.premium) {
        credits += 10;
      } else {
        credits += 1;
      }

      if (company.validEmailCount > 0) {
        credits += 3;
      }
    });

    return credits;
  };

  return (
    <div
      className={`searchScreen ${searched ? "noMargin" : ""} ${
        showExportPopUp ? "sep" : ""
      }`}
      onClick={screenClicked}
    >
      {tutorialStep !== "" && <div className="tutorialTint" />}

      {!loading && !searched && (
        <span className="display-md semibold gray-900">
          Discover Your Next Deal
        </span>
      )}
      <SearchBar
        setSearching={searching}
        setResults={resultsReceived}
        exportSuccessful={exportSuccessful}
        exportClicked={exportCount}
        clearClicked={clearCount}
        closeClicked={closeCount}
        freeMode={freeMode}
        plan={plan}
        searched={searched}
        saveFilterClicked={saveFilterCount}
        saveToast={saveToast}
        selectedCompanies={selectedBusinesses}
        hideExported={hideExported}
        setHideExported={setHideExported}
        setShowExportPopUpP={setShowExportPopUp}
        updateCredits={updateCredits}
      />

      <div className="searchScreenContainer">
        {!loading && !searched && (
          <div className="disclaimer maxWidth300 column ai-centre gap8">
            <span className="text-md semibold">Disclaimer</span>
            <span className="text-sm">
              We are currently developing a mobile experience. For now, we
              advise you to use the service on a computer for enhanced
              functionality and an optimum experience.
            </span>
          </div>
        )}

        {loading && (
          <div className="loadingDiv">
            <div className="loadingSpinner" />
            <span className="display-xs semibold gray-900">
              Loading results...
            </span>
          </div>
        )}

        {!loading && results.length > 0 && (
          <div className="searchResultsTopRow" ref={topRowRef}>
            <div className="column ai-start">
              <div className="row gap20 ai-centre">
                <span className="text-lg semibold gray-900">
                  {(selectedBusinesses.length === -1000
                    ? "Showing first "
                    : "") +
                    selectedBusinesses.length +
                    " companies selected"}
                </span>

                {/* {pCount>0 && <span className='text-sm gray-500 semibold'>{`(${pCount} Premium companies not shown)`}</span>}
                        {pCount>0 && <span className='text-sm gray-900 semibold underline pointer' onClick={upgrade}>Upgrade to view</span>} */}
              </div>
              <div className="row gap20 ai-centre">
                <span className="gray-600 text-xs medium">
                  {user.exports.length === 0 && user.plan === "free"
                    ? "First 25 selected will be exported"
                    : calculateCredits() + " Credits to export"}
                </span>
                <div
                  className="relative"
                  onMouseEnter={() => setShowCreditBreakdown(true)}
                  onMouseLeave={() => setShowCreditBreakdown(false)}
                >
                  <img
                    className="safInfo relative"
                    src={"assets/info.png"}
                    alt="infoIcon"
                  />
                  {showCreditBreakdown && (
                    <CreditBreakdownPopUp
                      selectedBusinesses={selectedBusinessesExpanded}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="appliedSmartTags">{getAppliedTags()}</div>
            <div className="appliedSmartTags">
              <div className="tooltip">
                <button
                  className="secondaryButton text-xs semibold gray-700"
                  disabled={false}
                  onClick={saveFilterClicked}
                >
                  Save filter
                </button>
                {/* <span className="tooltiptext button text-sm regular">Coming soon</span> */}
              </div>

              <div className="tooltip">
                <button
                  className={`primaryButton text-sm semibold white ${
                    tutorialStep === "export" ? "z400" : ""
                  }`}
                  onClick={exportClicked}
                  disabled={
                    credits === 0 ||
                    credits === null ||
                    selectedBusinesses.length === 0
                  }
                >
                  Export
                </button>
                {credits === 0 && (
                  <span className="tooltiptext button text-sm regular">
                    You are out of credits
                  </span>
                )}
                {credits === null && (
                  <span className="tooltiptext button text-sm regular">
                    Error loading your credits
                  </span>
                )}
                {selectedBusinesses.length === 0 && (
                  <span className="tooltiptext button text-sm regular">
                    You have not selected any businesses to export
                  </span>
                )}
                {tutorialStep === "export" && (
                  <TutorialBubble
                    step={tutorialStep}
                    close={() => setTutorialStep("")}
                    next={() => setTutorialStep("")}
                  />
                )}
              </div>
            </div>
          </div>
        )}

        {!loading && results.length > 0 && (
          <div className="width100 row jc-between">
            <div className="row items-center gap8">
              <div
                className="showDropdownSelect"
                onClick={() => setShowOptionOpen(!showOptionOpen)}
              >
                <span className="gray-700 text-xs semibold">
                  {showOptionSelected === "all"
                    ? "Show all companies"
                    : showOptionSelected === "premium"
                    ? "Show only premium companies"
                    : "Show only standard companies"}
                </span>
                <img
                  className="sicArrow"
                  src={"/assets/chevron-down.png"}
                  alt="sortArrow"
                />

                {showOptionOpen && (
                  <div className="showDropdownPopUp gray-700 text-sm medium">
                    <div
                      className="showDropdownOption top hover"
                      onClick={() => setShowOptionSelected("all")}
                    >
                      Show all companies
                    </div>
                    <div
                      className="showDropdownOption hover"
                      onClick={() => setShowOptionSelected("standard")}
                    >
                      Show only standard companies
                    </div>
                    <div
                      className="showDropdownOption bottom hover"
                      onClick={() => setShowOptionSelected("premium")}
                    >
                      Show only premium companies
                    </div>
                  </div>
                )}
              </div>
              <div className="row ai-centre gap-2">
                <label className="switch">
                  <input
                    type="checkbox"
                    defaultChecked={hideExported}
                    onClick={() => setHideExported(!hideExported)}
                  />
                  <span className="sliderSwitch round"></span>
                </label>
                <span className="text-xs medium gray-600">
                  Don’t show previously exported companies
                </span>
              </div>
            </div>

            <div className="row gap8">
              <span className="text-xs medium gray-600">
                {getResultsPageStr()}
              </span>
              <div className="row gap8">
                <svg
                  className={`${currentPage === 1 ? "" : "pointer"}`}
                  onClick={
                    currentPage === 1
                      ? null
                      : () => setCurrentPage(currentPage - 1)
                  }
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M12.5 15L7.5 10L12.5 5"
                    stroke={currentPage === 1 ? "#EAECF0" : "#475467"}
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <svg
                  className={`${
                    currentPage === Math.ceil(shownResults.length / 50)
                      ? ""
                      : "pointer"
                  }`}
                  onClick={
                    currentPage === Math.ceil(shownResults.length / 50)
                      ? null
                      : () => setCurrentPage(currentPage + 1)
                  }
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M7.5 15L12.5 10L7.5 5"
                    stroke={
                      currentPage === Math.ceil(shownResults.length / 50)
                        ? "#EAECF0"
                        : "#475467"
                    }
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
        )}

        {!loading && results.length > 0 && (
          <table className="tableEle">{showResults()}</table>
        )}

        {!loading && results.length > 0 && (
          <div className="width100 row jc-end gap8">
            <span className="text-xs medium gray-600">
              {getResultsPageStr()}
            </span>
            <div className="row gap8">
              <svg
                className={`${currentPage === 1 ? "" : "pointer"}`}
                onClick={
                  currentPage === 1
                    ? null
                    : () => setCurrentPage(currentPage - 1)
                }
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M12.5 15L7.5 10L12.5 5"
                  stroke={currentPage === 1 ? "#EAECF0" : "#475467"}
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <svg
                className={`${
                  currentPage === Math.ceil(results.length / 50)
                    ? ""
                    : "pointer"
                }`}
                onClick={
                  currentPage === Math.ceil(results.length / 50)
                    ? null
                    : () => setCurrentPage(currentPage + 1)
                }
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M7.5 15L12.5 10L7.5 5"
                  stroke={
                    currentPage === Math.ceil(results.length / 50)
                      ? "#EAECF0"
                      : "#475467"
                  }
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
        )}

        {/* {(!loading && results.length>0) && <SearchResultPager currentPage={currentPage} pages={Math.ceil(results.length/50)} change={(x)=>changePage(x)}/>} */}

        {!loading && results.length > 0 && freeMode && (
          <div className={`resultsFade ${!freeBannerOpen ? "closed" : ""}`}>
            <div className="row width100 jc-end padding20">
              {freeBannerOpen && (
                <svg
                  className="pointer"
                  onClick={() => setFreeBannerOpen(false)}
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M4 6L8 10L12 6"
                    stroke={primary600}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )}
              {!freeBannerOpen && (
                <svg
                  className="pointer"
                  onClick={() => setFreeBannerOpen(true)}
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M12 10L8 6L4 10"
                    stroke="#D70471"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )}
            </div>
            <div
              className={`${
                freeBannerOpen ? "column" : "row"
              } jc-centre ai-centre gap20 height100`}
            >
              <div className="column ai-centre gap8">
                <span className="display-xs semibold gray-900">
                  Further information is for insiders
                </span>
                <span className="text-sm regular gray-600">
                  Upgrade to your Bizcrunch Plan and get all results.
                </span>
              </div>
              <button className="primaryButton" onClick={upgrade}>
                Upgrade Plan
              </button>
            </div>
          </div>
        )}

        {!loading && searched && results.length === 0 && (
          <div className="noResults">
            <img
              className="noResultsIcon"
              src="/assets/search-lg.png"
              alt="searchIcon"
            />
            <div className="noResultsInner">
              <span className="text-lg semibold gray-900">
                No matches found for your search.
              </span>
              <span className="test-sm regular gray-600">
                Try removing the current filters applied and start a new search
              </span>
            </div>
            <button className="secondaryButton" onClick={clearClicked}>
              Clear search
            </button>
          </div>
        )}
      </div>

      {/* {showCreditsPopUp && <NotEnoughCreditsPopUp credits={credits} companies={exportBalance} close={()=>setShowCreditsPopUp(false)} exportComp={()=>setExportCount(exportCount+1)}/>} */}
    </div>
  );
}
