import "../../../../styling/filters.css";
import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useContext,
} from "react";
import { ParentContext } from "../../../constants/ParentContext";
import { Mixpanel } from "../../../Mixpanel/Mixpanel";

export default function SliderFilter({ filter, addedFilters, apply, plan }) {
  // #region CONSTANTS & STATE VARIABLES

  let min = 0;
  let max = 100;

  const [checkedEstimated, setCheckedEstimated] = useState(true);
  const context = useContext(ParentContext);
  const isBiz = context.isBiz4Biz || context.isHarbour ? false : true;

  // const onChange=( min, max ) => {

  //     setMinVal(min.min)
  //     setMaxVal(min.max)
  // } // eslint-disable-line react-hooks/exhaustive-deps

  const [minVal, setMinVal] = useState(min);
  const [maxVal, setMaxVal] = useState(max);
  const minValRef = useRef(min);
  const maxValRef = useRef(max);
  const range = useRef(null);
  let user = JSON.parse(localStorage.getItem("user"))

  // Convert to percentage
  const getPercent = useCallback(
    (value) => Math.round(((value - min) / (max - min)) * 100),
    [min, max]
  );

  // Set width of the range to decrease from the left side
  useEffect(() => {
    const minPercent = getPercent(minVal);
    const maxPercent = getPercent(maxValRef.current);

    if (range.current) {
      range.current.style.left = `${minPercent}%`;
      range.current.style.width = `${maxPercent - minPercent}%`;
    }
  }, [minVal, getPercent]);

  // Set width of the range to decrease from the right side
  useEffect(() => {
    const minPercent = getPercent(minValRef.current);
    const maxPercent = getPercent(maxVal);

    if (range.current) {
      range.current.style.width = `${maxPercent - minPercent}%`;
    }
  }, [maxVal, getPercent]);

  // Get min and max values when their state changes

  // useEffect(() => {
  //   onChange({ min: minVal, max: maxVal });
  // }, [minVal, maxVal, onChange]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    addedFilters.forEach((element) => {
      if (element.id === filter.id) {
        setMinVal(element.value.min);
        setMaxVal(element.value.max);
        setCheckedEstimated(element.value.estimated);
      }
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // #endregion

  // #region SHOW COMPONENTS

  const isDisabled = () => {
    if (
      (filter.id === "3B1" || filter.id === "3C1" || filter.id === "3B2") &&
      plan.includes("starter")
    ) {
      return true;
    } else {
      return false;
    }
  };

  // #endregion

  // #region WEB REQUESTS

  // #endregion

  // #region BUTTONS CLICKED
  const applyFilter = () => {

    Mixpanel.track("FeatureUsage", {
      $name: user?.name, 
      $email: user?.email, 
      "Plan": user?.plan,
      "User Type": "Buyer",
      "User ID": user?.uid,
      "Date": new Date().toISOString(),
      "Feature":`Applied ${filter.title} Filter`
    });

    let value = {
      min: minVal,
      max: maxVal,
      estimated: filter.hasEstimate ? checkedEstimated : false,
    };
    apply(filter, value);
  };
  // #endregion

  // #region OTHER

  // #endregion

  return (
    <div className="rangeFilter">
      <div className="container">
        <div className="slider">
          <input
            type="range"
            min={min}
            max={max}
            value={minVal}
            onChange={(event) => {
              const value = Math.min(Number(event.target.value), maxVal - 1);
              setMinVal(value);
              minValRef.current = value;
            }}
            className="thumb thumb--left"
            style={{ zIndex: minVal > max - 100 && "5" }}
          />
          <div className="slider__track" />
          <div ref={range} className="slider__range" />
          <div className="slider__left-value">{minVal}%</div>
          <div className="slider__right-value">{maxVal}%</div>
          <input
            type="range"
            min={min}
            max={max}
            value={maxVal}
            onChange={(event) => {
              const value = Math.max(Number(event.target.value), minVal + 1);
              setMaxVal(value);
              maxValRef.current = value;
            }}
            className="thumb thumb--right"
          />
        </div>
      </div>

      {filter.hasEstimate && (
        <div className="revenueInputRow">
          <input
            id="checkbox_id"
            className="input-md checkbox"
            type="checkbox"
            checked={checkedEstimated}
            onChange={() => setCheckedEstimated(!checkedEstimated)}
          />
          <label htmlFor="checkbox_id" className="checkboxLabel" />
          {/* <img className='revEstIcon' src='/assets/profitable.png'/> */}
          <span className="text-sm medium gray-700">~ Estimated Bizcrunch</span>
        </div>
      )}

      <div className="row width100 jc-start">
        <div className="tooltip">
          <button
            className="secondaryButton applyFilterBtn"
            disabled={isDisabled()}
            onClick={applyFilter}
          >
            <img
              className="secondaryButtonIcon"
              src={
                isDisabled()
                  ? "/assets/grey-plus.png"
                  : isBiz
                  ? "/assets/red-plus.png"
                  : "/assets/blue-plus.png"
              }
              alt="plus  "
            />
            <span
              className={`text-xs semibold ${
                isDisabled() ? "gray-300" : "primary-700"
              }`}
            >
              Apply filter
            </span>
          </button>
          {isDisabled() && (
            <span className="tooltiptext button text-sm regular">
              You must upgrade to use this filter
            </span>
          )}
        </div>
      </div>
    </div>
  );
}
